import React, { useState } from "react";
import "../../../../assests/styles/PepExposureInformation.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import useHivCare from "../HivLabService/useHivCare";
import { useDispatch } from "react-redux";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
const PepExposureInformation: React.FC<StepProps> = ({
  onNext,
  onPrevious,
}) => {
  const [exposureDate, setExposureDate] = useState({
    month: "",
    day: "",
    year: "",
  });
  const [exposureType, setExposureType] = useState<string[]>([]);
  const [otherExposureType, setOtherExposureType] = useState<string>("");
  const [details, setDetails] = useState<string>("");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const dispatch = useDispatch();
  const { GetPEPServices } = useHivCare();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days =
    exposureDate.month && exposureDate.year
      ? Array.from(
          {
            length: new Date(
              Number(exposureDate.year),
              months.indexOf(exposureDate.month) + 1,
              0
            ).getDate(),
          },
          (_, i) => (i + 1).toString()
        )
      : [];

  const years = Array.from({ length: currentYear - 2008 }, (_, i) =>
    (2009 + i).toString()
  );

  const filteredMonths =
    exposureDate.year === currentYear.toString()
      ? months.slice(0, currentMonth + 1)
      : months;

  const filteredDays =
    exposureDate.year === currentYear.toString() &&
    exposureDate.month === months[currentMonth]
      ? days.slice(0, currentDay)
      : days;

  const handleDateChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    const { value } = e.target;
    setExposureDate((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const date = `${exposureDate.day}-${exposureDate.month}-${exposureDate.year}`;
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setExposureType((prev) =>
      checked ? [...prev, name] : prev.filter((item) => item !== name)
    );
  };

  const handleOtherExposureChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setOtherExposureType(value);
    setExposureType((prev) => {
      const filteredPrev = prev.filter((item) => !item.startsWith("Other"));
      return value ? [...filteredPrev, `Other: ${value}`] : filteredPrev;
    });
  };

  GetPEPServices.pepExposureinformation(dispatch, exposureType, details, date);
  var displayError = false;
  const setError = () => {
    displayError = false;
    if (date ===``) {
      displayError = true;
      return "Please select a Date of potential HIV exposure";
    } else if (exposureType.length === 0) {
      displayError = true;
      return "Please select atleast one Type of exposure";
    } else if (details === '') {
      displayError = true;
      return "Please provide a brief description of the exposure incident";
    }

    displayError = false;
    return;
  };
  const errorMessage = setError()
  return (
    <div className="exposure-information">
      <h2>Exposure Information</h2>

      <div className="section">
        <h3>Date of potential HIV exposure:</h3>
        <div className="date-selectors">
          <select
            value={exposureDate.month}
            onChange={(e) => handleDateChange(e, "month")}
          >
            <option value="">Month</option>
            {filteredMonths.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <select
            value={exposureDate.day}
            onChange={(e) => handleDateChange(e, "day")}
          >
            <option value="">Day</option>
            {filteredDays.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
          <select
            value={exposureDate.year}
            onChange={(e) => handleDateChange(e, "year")}
          >
            <option value="">Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="section">
        <h3>Type of exposure: (Select all that apply)</h3>
        <label>
          <input
            type="checkbox"
            name="Sexual exposure"
            checked={exposureType.includes("Sexual exposure")}
            onChange={handleCheckboxChange}
          />
          Sexual exposure
        </label>
        <label>
          <input
            type="checkbox"
            name="Needle stick injury"
            checked={exposureType.includes("Needle stick injury")}
            onChange={handleCheckboxChange}
          />
          Needle stick injury
        </label>
        <label>
          <input
            type="checkbox"
            name="Sharing needles or injection equipment"
            checked={exposureType.includes(
              "Sharing needles or injection equipment"
            )}
            onChange={handleCheckboxChange}
          />
          Sharing needles or injection equipment
        </label>
        <label>
          <input
            type="checkbox"
            name="Sexual assault"
            checked={exposureType.includes("Sexual assault")}
            onChange={handleCheckboxChange}
          />
          Sexual assault
        </label>
        <label>
          <input
            type="checkbox"
            name="Other"
            checked={exposureType.some((type) => type.startsWith("Other"))}
            onChange={handleCheckboxChange}
          />
          Other:
        </label>
        {exposureType.some((type) => type.startsWith("Other")) && (
          <input
            type="text"
            value={otherExposureType}
            onChange={handleOtherExposureChange}
            placeholder="Please specify"
          />
        )}
      </div>

      <div className="section">
        <h3>
          Details of exposure: (Please provide a brief description of the
          exposure incident)
        </h3>
        <textarea
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          rows={10}
          maxLength={1000} // Limit to 200 words
          placeholder="Provide a detailed description"
        />
      </div>
      <div className="flex gap-4">
          <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
          </button>
          <button type="submit" className="submit-button" onClick={onNext} disabled={errorMessage? true : false}>
            Next
          </button>
        </div>
        <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default PepExposureInformation;
