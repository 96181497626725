const SecondDocScreen = () => {
    return (<div>


        Second Doctor screen



    </div>)
}

export default SecondDocScreen;