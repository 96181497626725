import profile from '../../assests/images/Ellipse 69.svg'
export default function ChatUserProfileDetails() {
  return (
    <div>
        <div className='flex flex-col justify-center items-center'>
        <div className=''>
                <img src={profile} alt='logo' className='w-[60px] h-[6x0px] rounded-[50%]'/>
            </div>
            <div className='flex flex-col justify-center items-center'>
            <h3 className='topographyText font-[600] flex gap-2 text-[20px] items-center'>Dr mike james </h3>
                <p className='topographyText text-[20px] font-[400] text-[#596780] '>XXX Medical Center</p>
                <p className='topographyText text-[20px] font-[400] text-[#596780] '>Department: Department 1</p>
            </div>
        </div>
    </div>
  )
}
