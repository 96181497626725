import React from "react";
import { useNavigate } from "react-router-dom";
import "../../src/assests/styles/NotFound.css";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <h1 className="notfound-title">Oops... Page Not Found</h1>
        <p className="notfound-message">
          Sorry! The page you're looking for doesn't exist. You can go back or
          head to the homepage.
        </p>

        <div className="notfound-buttons">
          <button className="notfound-btn" onClick={() => navigate(-1)}>
            Go Back
          </button>
          <button className="notfound-btn" onClick={() => navigate("/")}>
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
