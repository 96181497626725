import { createAsyncThunk } from "@reduxjs/toolkit";
import RequestService from "services/requestService";
import { setErrors, resetHivCareState } from "./HivCare.slice";


export const createHivCareService = createAsyncThunk(
  "createRequestLabService",
  async (PostRequest: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await RequestService.postMethod(
        "hiv-care-service/create",
        PostRequest
      );

      dispatch(resetHivCareState())

      return response.data;
    } catch (error: any) {
      // console.log("Error caught:", error);

      if (error.response) {
        const errorMessage =
          error.response.data.message || error.response.statusText;
        const statusCode = error.response.status;
        dispatch(setErrors(error.response.data.message));
        switch (true) {
          case statusCode === 404:
            break;
          case statusCode >= 500:
            break;
          case errorMessage.includes("could not be found"):
            break;
          case errorMessage.includes("Network Error"):
            break;
          default:
        }

        return rejectWithValue(errorMessage);
      } else if (error.request) {
        return rejectWithValue(
          "No response from server, please try again later."
        );
      } else {
        return rejectWithValue("An unexpected error occurred.");
      }
    }
  }
);
