import localStorageService from "./localStorageService"


const isAuthenticated = async () => {
    try {
        const isAuth = await localStorageService.getStorageData('user');

        if (isAuth !== null && isAuth.token) {

            return true;
        }

        return false
    } catch (error) {
        return false;
    }
}

const AuthService = {

    isAuthenticated
}



export default AuthService;