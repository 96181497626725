import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../../assests/styles/PhoneVerificationPopup.css'; // Import the external CSS file

interface PhoneVerificationPopupProps {
  onDismiss?: () => void; // Make onDismiss optional
}

const PhoneVerificationPopup: React.FC<PhoneVerificationPopupProps> = ({ onDismiss }) => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Automatically dismiss the popup after 10 seconds
    const timer = setTimeout(() => {
      setVisible(false);
      if (onDismiss) onDismiss();
      navigate('/add-details'); // Navigate to the next page
    }, 10000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onDismiss, navigate]);

  const handleDismiss = () => {
    setVisible(false);
    if (onDismiss) onDismiss();
    navigate('/add-details'); // Navigate to the next page
  };

  if (!visible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h1>Your phone number has been verified</h1>
        <p>Congratulations! Your phone number has been verified.</p>
        <button className="popup-button" onClick={handleDismiss}>Done</button>
      </div>
    </div>
  );
};

export default PhoneVerificationPopup;
