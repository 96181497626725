//import PatientLogin from "../../views/patients/pages/auth/login";
import PatientRegistration from "../../views/patients/pages/auth/register";
import MyDocument from "../../views/patients/pages/auth/mydocument";
import MedicalHistory from "../../views/patients/pages/auth/medicalhistory";
import SavedServiceCenter from "../../views/patients/pages/auth/savedServiceCenter";
import HivTestingPatientDetails from "views/patients/pages/requestLabService/HivTestingPatientDetails";
import FindService from "../../views/patients/pages/findServices";
import SeeDoctor from "../../views/patients/pages/seeDoctor";
import AddAccounts from "../../views/patients/pages/auth/AccountSetting/AddAccounts";
import PatientDashBoard from "views/patients/pages/dashboard/Dashboard";
import Login from "views/patients/pages/LoginPage/Login";
import MorePatientInfoForm from "views/patients/pages/MorePatientInformation/MorePatientInfoForm";
import MyMedications from "views/patients/pages/MyMedications/MyMedications";
import EmailCodeVarification from "components/Registration/EmailCodeVarification";
import RequestLabServices from "../../views/patients/pages/requestLabService";
import ChatIndex from "views/patients/pages/Chat/ChatIndex";
import ErrorIndex from "components/ErrorComponents/ErrorIndex";
import PhoneVerification from "components/Registration/PhoneVerificaton";
import HivScreeningPage from "views/patients/pages/HIVScreening/HivScreeningPage";
import ChatMainBox from "components/Chat/ChatMainBox";
import HivLabService from "views/patients/pages/HivLabService/HivLabService.index";
import ChangePasswordPage from "views/patients/pages/passwordReset";
import CurrentHivInformation from "../../components/RequestLabServiceComponents/CurrentHivInformation";
import ConsentDatause from "../../components/RequestLabServiceComponents/ConsentDatause";
import ARTHivStatus from "components/RequestLabServiceComponents/ARTHivStatus";
import CurrentMentalHealth from "components/RequestLabServiceComponents/CurrentMentalHealth";
import PepExposureInformation from "views/patients/pages/PepExposureInformation/PepExposureInformation";
import NotFound from "components/NotFound";
import VerifyEmailToken from "views/patients/pages/EmailVerification";
const patientRoute = [
    {
        path: "/",
        component: PatientDashBoard,
        isAuth: true,
    },
    {
        path: "/hiv",
        component: HivTestingPatientDetails,
        isAuth: false,
    },

    {
        path: "/SignIn",
        component: Login,
        isAuth: false,
    },
    {
        path: "/screen",
        component: HivScreeningPage,
        isAuth: true,
    },
    {
        path: "/artstatus",
        component: ARTHivStatus,
        isAuth: true,
    },
    {
        path: "/chat",
        component: ChatIndex,
        isAuth: true,
    },
    {
        path: "/hiv-info",
        component: CurrentHivInformation,
        isAuth: true,
    },
    {
        path: "/consent",
        component: ConsentDatause,
        isAuth: true,
    },
    {
        path: "/error",
        component: ErrorIndex,
        isAuth: true,
    },
    {
        path: "/hiv-care",
        component: HivLabService,
        isAuth: true,
    },
    {
        path: "/mental-care",
        component: CurrentMentalHealth,
        isAuth: true,
    },
    {
        path: "/verificode",
        component: EmailCodeVarification
        ,
    },
    {
        path: "/verifyPhone",
        component: PhoneVerification,
        isAuth: true
    },

    {
        path: "/prescription",
        component: MyMedications,
        isAuth: true,
    },

    {
        path: "/add-details",
        component: MorePatientInfoForm,
        isAuth: true,
    },
    {
        path: "/userform",
        component: MorePatientInfoForm,
        isAuth: true,
    },
    {
        path: "/SignUp",
        component: PatientRegistration,
        isAuth: false,
    },

    {
        path: "/message",
        component: ChatMainBox,
        isAuth: true,
    },

    {
        path: "/dashboard",
        component: PatientDashBoard,
        isAuth: true,
    },

    {
        path: "/documents",
        component: MyDocument,
        isAuth: true,
    },
    {
        path: "/medical-history",
        component: MedicalHistory,
        isAuth: true,
    },
    {
        path: "/lab-results",
        component: SavedServiceCenter,
        isAuth: true,
    },

    {
        path: "/find-service",
        component: FindService,
        isAuth: true,
    },

    {
        path: "/see-doctor",
        component: SeeDoctor,
        isAuth: true,
    },
    {
        path: "/your-account",
        component: AddAccounts,
        isAuth: true,
    },

    {
        path: "/request-lab-service",
        component: RequestLabServices,
        isAuth: true,
    },

    {
        path: "/password-reset/:id",
        component: ChangePasswordPage,
        isAuth: false,
    },

    {
        path: "/prep_status",
        component: PepExposureInformation,
        isAuth: true,
    },
    {
        path: "/changepass",
        component: ChangePasswordPage,
        isAuth: false,
    },



    // Added the catch-all route for 404 at the end
    {
        path: "*",
        component: NotFound,
        isAuth: true,
    },
    {
        path: "*",
        component: NotFound,
        isAuth: false,
    },

    {
        path: "backend/public/api/patient/email/verify/:id/:token",
        component: VerifyEmailToken,
        isAuth: false,
    },

    



];

export default patientRoute;
