import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import useLoginForm from "hooks/useLoginForm";
import ValidationTitle from "components/shared/validation";
import RequestService from "services/requestService";
import { useDispatch } from 'react-redux';
import NetlifeRegLogo from "../../../../assests/icons/netlifRegLogo.svg";
import { setIsAuthenticated, setUserInfo } from '../../../../Features/auth/auth.slice';
import localStorageService from "services/localStorageService";
import Loader from "components/shared/Loader";
import ResetPassword from './ResetPassword';
import '../../../../assests/styles/ShowPassword.css'
import { setSession } from "services/sessionManagement";

export default function Login() {
  const {
    handleChange,
    validateLogin,
    errors,
    loginValues,
    setLoginErrors,
    setFormSubmitStatus
  } = useLoginForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = (e: any) => {
    e.preventDefault();
    const status = validateLogin();

    if (status) {
      submitFormData();
    }
  };

  const setErrorResponse = (errors: { [key: string]: string[] }) => {
    for (let key in errors) {
      setLoginErrors(key, errors[key][0]);
    }
  };

  const submitFormData = async () => {
    setFormSubmitStatus(true);

    RequestService.postMethod('login', loginValues)
      .then(async (response) => {
        const responseData = response.data.responseData;

        dispatch(setUserInfo(responseData));
        await localStorageService.setLocalStorageData('user', responseData);
        await setSession('session')
        
        setFormSubmitStatus(false);
        dispatch(setIsAuthenticated(true));

        if (responseData.gender) {
          navigate('/dashboard');
        } else {
          navigate('/add-details');
        }
      })
      .catch(error => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setLoginErrors('password', errorData?.responseData);
        }
        if (errorData?.status && errorData?.errors) {
          const errors = errorData.errors;
          setErrorResponse(errors);
        }
      });
  };

  const openForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <div className="registration-container">
        <div className="registration-form">
          <h2>We missed you!</h2>
          <p>
            Today is a new day. It's your day. Sign in to your account and get health care now.
          </p>
          <form>
            <div>
              <label>Email</label>
              <input
                type="text"
                placeholder="williamfavorx@gmail.com"
                value={loginValues.email}
                onChange={(e) => handleChange(e)}
                name="email"
              />
              {errors.email && <ValidationTitle title={errors.email} />}
            </div>

            <div>
              <label>Password</label>
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="At least 8 characters"
                name='password'
                value={loginValues.password}
                onChange={(e) => handleChange(e)}
              />
              {errors.password && <ValidationTitle title={errors.password} />}
            </div>

            {/* Custom Show Password Container */}
            <div className="show-password-container">
              <input
                type="checkbox"
                checked={passwordVisible}
                onChange={togglePasswordVisibility}
                className="custom-checkbox"
              />
              <label className="custom-label">Show Password</label>
            </div>

            <div className="my-2">
              <p className="float-end">
                Forgot <span className="text-[#1E4AE9] cursor-pointer" onClick={openForgotPassword}>Password?</span>
              </p>
            </div>
            <button
              onClick={handleLogin}
              type="submit"
              disabled={loginValues.is_form_submitted}
              className="flex justify m-0"
            >
              {loginValues.is_form_submitted ? <div className="flex justify-center items-center w-full h-full m-0"><Loader /></div> : 'Sign in'}
            </button>
          </form>
          <p className="already cursor-pointer" onClick={() => navigate('/SignUp')}>
            Don't you have an account? <span>Sign up</span>
          </p>

          <div className="cop-right">
            <ul>
              <li><div>© 2024 NetLife</div></li>
              <li><div>About Us</div></li>
              <li><div>Terms of Service</div></li>
              <li><div>Privacy Policy</div></li>
            </ul>
          </div>
        </div>
        <div className="login-image">
          <div className="overlay">
            <h3>
              See your health care <br /> anytime, anywhere
            </h3>
            <img src={NetlifeRegLogo} alt="reg icon" />
          </div>
        </div>
      </div>

      {showForgotPassword && (
        <ResetPassword onClose={closeForgotPassword} className="forgot-password-container" />
      )}
    </div>
  );
}
