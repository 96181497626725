import localStorageService from "./localStorageService";
import RequestService from "./requestService";

// Function to store session with a timestamp
export const setSession = (key: string) => {
    const sessionData = {
        timestamp: Date.now() // Store the current timestamp
    };
    localStorage.setItem(key, JSON.stringify(sessionData));
}

// Function to get session data
export const checkUserSession = async (key: string) => {
    const sessionData = localStorage.getItem(key);
    if (!sessionData) {

        setSession(key);
        return false;

    };

    const { timestamp } = JSON.parse(sessionData);
    const now = Date.now();

    // Check if more than 2 minutes (120000 ms) have passed since the last activity
    if (now - timestamp >600000 ) {
        clearSession(key);
        try {
            await logoutUser();
            return false;
        }
        catch (error) {
            return false;
        }
    }

    return true;


}

// Function to clear session
const clearSession = (key: string) => {
    localStorage.removeItem(key);
    //console.log('Session expired or cleared');
}

const logoutUser = async () => {

    await localStorageService.clearStorage('user');

    await RequestService.getMethod('logout');

}


