/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import RequestService from "services/requestService";
import localStorageService from "services/localStorageService";
import { useDispatch } from "react-redux";
import {
  setUserInfo,
  setIsAuthenticated,
} from "../../Features/auth/auth.slice";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import "assests/styles/sessionwarningmodel.css";

const WarningModal: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes = 120 seconds
  const [showModal, setShowModal] = useState(false);
  const countdownInterval = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    setShowModal(true);
    startCountdown();
    setTimeLeft(120);
  };

  const handleContinue = () => {
    setShowModal(false);
    // window.location.reload();
    clearInterval(countdownInterval.current!);
    // onContinue(); // Any additional logic on continue
  };

  const handleLogout = async () => {
    clearInterval(countdownInterval.current!);
    // Redirect to login page

    Promise.all([
      await RequestService.getMethod("logout"),
      await localStorageService.clearStorage("user"),
      dispatch(setUserInfo({})),
      dispatch(setIsAuthenticated(false)),
      // Force reload
      navigate("/SignIn")
    ])
   
  };

  const startCountdown = () => {
    countdownInterval.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownInterval.current!);
          handleLogout();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleActive = () => {
    if (showModal) {
      // handleContinue();
    }
  };

  const handleOnAction = () => {
    // Reset the idle timer if any action happens
    if (showModal) {
      // handleContinue();
    }
  };

  useIdleTimer({
    timeout: 300000, // 7 minutes of inactivity
    onIdle: handleOnIdle,
    onActive: handleActive,
    onAction: handleOnAction,
    debounce: 100,
  });

  // useEffect(() => {
  //   return () => {
  //     if (countdownInterval.current) {
  //       clearInterval(countdownInterval.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // If the tab becomes active and the countdown is 0 or below, log out
      if (document.visibilityState === "visible" && timeLeft <= 0) {
        handleLogout();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, []);

  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="modal-title">Are you still there?</h2>
            <p className="modal-text">
              You’ve been inactive for a while. For security reasons, you’ll be
              automatically signed out in 2 minutes. <br />
              Is that fine with you?
            </p>
            <div className="flex w-full">
              <div className="mx-auto font-medium modal-bold">
                {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? "0" : ""}
                {timeLeft % 60}
              </div>
            </div>
            <div className="modal-buttons">
              <button className="modal-button no" onClick={handleContinue}>
                NO
              </button>
              <button className="modal-button yes" onClick={handleLogout}>
                YES
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WarningModal;
