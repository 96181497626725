import React from 'react';
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import Questions from "./Question";
import '../../assests/styles/request-lab-service.css';
import { StepProps } from './stepsProps';
import useStates from 'hooks/useStates';
const RequestLabService3: React.FC<StepProps> = ({ onNext, onPrevious, onNotEligible }) => {
  const {first_name} = useStates()
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />Forgot something ?
        </div>
      </div>
      <div className="heading2">Welcome, <span className="highlight-text">{first_name || null} </span></div>
      <p>Before we proceed with your HIV test request, please answer the following screening questions to help us understand your risk level and provide the best care possible.</p>
      <div className="heading3">Screening Questions</div>
      <Questions onNext={onNext} onPrevious={onPrevious} onNotEligible={onNotEligible} />
    </div>
  );
};

export default RequestLabService3;
