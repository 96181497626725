import React from 'react';
import '../../../../assests/styles/ErrorComponent.css';

interface ErrorComponentProps {
  showError: boolean;
  message: any;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ showError, message }) => {
  if (!showError) return null;

  return (
    <div className="error-message">
      <p>{message}</p>
    </div>
  );
};

export default ErrorComponent;
