import React, { useEffect, useRef } from 'react';
import '../../assests/styles/request-lab-service.css';

interface DateInputProps {
  value: string | null;
  onChange: (value: string) => void;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value; // format: YYYY-MM-DD
    onChange(dateValue);
  };

  const formatDateForDisplay = (date: string | null) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const today = new Date().toISOString().split('T')[0];
      inputElement.onfocus = () => {
        inputElement.type = 'date';
        inputElement.min = today; 
      };
      inputElement.onblur = () => {
        if (!inputElement.value) {
          inputElement.type = 'text';
        }
      };
      inputElement.type = 'text'; 
      inputElement.placeholder = 'DD/MM/YYYY';
    }
  }, []);

  return (
    <div className="date-input-container">
      <div className="date-input">
        <input 
          ref={inputRef}
          type="text" 
          value={value || ''} 
          onChange={handleDateChange} 
          className="date-input-field"
          placeholder="DD/MM/YYYY"
        />
      </div>
      {value && (
        <div className="formatted-date-display">
          You have selected: {formatDateForDisplay(value)}
        </div>
      )}
    </div>
  );
};

export default DateInput;
