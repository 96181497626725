// src/components/Loader.tsx

import React from 'react';
import '../../assests/styles/windowLoader.css'
const WindowLoader: React.FC = () => {
    return (
        

        <div className="loader">
            <div className="justify-content-center jimu-primary-loading"></div>
        </div>


    );
};

export default WindowLoader;
