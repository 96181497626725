import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import "../../../../assests/styles/DeliveryDateTime.css";

import { useSelector } from "react-redux";
type BirthDay = {
  handleChange: (value: string) => void;
};

const BirthdayForm: React.FC<BirthDay> = ({ handleChange }) => {
  const dateOfbirth = useSelector(
    (state: any) => state.auth.userInfo.date_of_birth
  );

  const [selectedyear, selectedmonth, selectedday] = dateOfbirth
    ? dateOfbirth.split("-")
    : "";
  const [day, setDay] = useState<number | string>(
    selectedday ? selectedday : ""
  );
  const [month, setMonth] = useState<any>(selectedmonth ? selectedmonth : "");
  const [year, setYear] = useState<number | string>(
    selectedyear ? selectedyear : ""
  );
  const [error, setError] = useState<string | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateParent = () => {
    const formattedDate = `${year}-${month}-${day}`;
    handleChange(formattedDate);
    //console.log(formattedDate);
  };

 


  const currentDate = useMemo(() => new Date(), []);
  const currentYear = currentDate.getFullYear();
  const minYear = currentYear - 80; // For people up to 80 years old
  const maxYear = currentYear - 15;
  const handleDateChange = (
    setter: React.Dispatch<React.SetStateAction<any>>,
    value: any
  ) => {
    setter(value);
  };



  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];


 
  const generateDays = (month:string, year:number) => {
    const monthIndex = months.findIndex(m => m === month);
    if (monthIndex === -1) return [];
    return Array.from({ length: new Date(year, monthIndex + 1, 0).getDate() }, (_, i) => i + 1);
  };

  const days = month && year ? generateDays(month, Number(year)) : [];

  // Filter out past days

  const updateFormatedDate = async(setValue:any, value:any) => {
    await handleDateChange(setValue,value);
    await updateParent();

  }
  

  const availableMonths =
    year && Number(year) === currentDate.getFullYear()
      ? months.slice(currentDate.getMonth())
      : months;

      useEffect(() => {
        if (year && year.toString().length === 4) {
          const currentYear = new Date().getFullYear();
          const age = currentYear - Number(year);
          if (age >= 15) {
            setError(null);
          }
        }
       // updateParent();
      }, []);


  return (
    <div className="delivery-date-time">
      <div className="date-selectors section">
        <div className="input-group h-max-40">
          <label htmlFor="year">Year</label>
          <select
            id="year"
            value={year}
            onChange={(e) => updateFormatedDate(setYear, Number(e.target.value))}
            className="select-scrollable"
           
          >
            <option value="">Year</option>
            {Array.from(
              { length: maxYear - minYear + 1 },
              (_, i) => minYear + i
            ).map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="month">Month</label>
          <select
            id="month"
            value={month}
            onChange={(e) => updateFormatedDate(setMonth, e.target.value)}
          >
            <option value="">Month</option>
            {availableMonths.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="day">Day</label>
          <select
            id="day"
            value={day}
            onChange={(e) => updateFormatedDate(setDay, Number(e.target.value))}
          >
            <option value="">Day</option>
            {days.map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
        </div>

        {/* </div> */}
      </div>
      {error && <p className="text-customRed mt-2">{error}</p>}
    </div>
  );
};

export default BirthdayForm;
