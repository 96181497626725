import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/headerImages/Layer 2.png';
import dashboardImage from '../../medicalhistoryImage/Dash.png';
import appointmentImage from '../../medicalhistoryImage/Appoint.png';
import requestLabServicesImage from '../../medicalhistoryImage/request.png';
import findServiceCenterImage from '../../medicalhistoryImage/find.png';
import seeDoctorImage from '../../medicalhistoryImage/Doctor.png';
import prescriptionImage from '../../medicalhistoryImage/prescrip.png';
import labResultsImage from '../../medicalhistoryImage/Report.png';
import medicalHistoryImage from '../../medicalhistoryImage/medical.png';
import documentImage from '../../medicalhistoryImage/Document.png'; 
import '../../assets/sidnavmob.css';

interface MobileSideNavBarProps {
  onClose: () => void;
  onLogout: () => void;
}

const MobileSideNavBar: React.FC<MobileSideNavBarProps> = ({ onClose, onLogout }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleLogout = () => {
    onLogout();
    onClose();
  };

  return (
    <>
      <div className="block md:hidden mobile-side-nav-custom"
        style={{
          position: 'fixed',
          top: 0,
          left: isMounted ? 0 : '-100%',
          bottom: 0,
          width: '80%',
          maxWidth: '384px',
          zIndex: 9999,
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          padding: '20px',
          transition: 'left 300ms ease-in-out',
          overflowY: 'auto',
        }}
      >
        <div className="nav-content-custom">
          <img src={logo} alt="Logo" className="nav-logo-custom" />
          <h1 className="nav-section-title-custom">Get Care</h1>
          <Link to="/dashboard" onClick={onClose} className="nav-link-custom">
            <NavItem image={dashboardImage} text="Dashboard" />
          </Link>
          <Link to="/appointment" onClick={onClose} className="nav-link-custom">
            <NavItem image={appointmentImage} text="Appointment" />
          </Link>
          <Link to="/request-lab-service" onClick={onClose} className="nav-link-custom">
            <NavItem image={requestLabServicesImage} text="Request Lab Services" />
          </Link>
          <Link to="/find-service-center" onClick={onClose} className="nav-link-custom">
            <NavItem image={findServiceCenterImage} text="Find Service Center" />
          </Link>
          <Link to="/see-doctor" onClick={onClose} className="nav-link-custom">
            <div className="see-doctor-button-custom">
              <img src={seeDoctorImage} alt="See Doctor" className="see-doctor-icon-custom" />
              See a Doctor
            </div>
          </Link>
          <h1 className="nav-section-title-custom">My Health Records</h1>
          <Link to="/prescription" onClick={onClose} className="nav-link-custom">
            <NavItem image={prescriptionImage} text="Prescription" />
          </Link>
          <Link to="/lab-results" onClick={onClose} className="nav-link-custom">
            <NavItem image={labResultsImage} text="Lab Results" />
          </Link>
          <Link to="/medical-history" onClick={onClose} className="nav-link-custom">
            <NavItem image={medicalHistoryImage} text="Medical History" />
          </Link>
          <Link to="/documents" onClick={onClose} className="nav-link-custom">
            <NavItem image={documentImage} text="Documents" />
          </Link>
          <div className="secure-info-custom">
            <div className="secure-info-content-custom">
              <h2 className="secure-info-title-custom">Secure your health information</h2>
              <button
                className="logout-button-custom"
                onClick={handleLogout}
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMounted && (
        <div
          className="nav-backdrop-custom"
          onClick={onClose}
        />
      )}
    </>
  );
};

interface NavItemProps {
  image: string;
  text: string;
}

const NavItem: React.FC<NavItemProps> = ({ image, text }) => {
  const [, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="nav-item-custom"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={image} alt={text} className="nav-item-icon-custom" />
      <span className="nav-item-text-custom">
        {text}
      </span>
    </div>
  );
};

export default MobileSideNavBar;
