/* eslint-disable no-unreachable */

import React from "react";

import { NameProp, useUserInfoFormType } from "./types";
import { errorType } from "./types";





const initialuseUserInfoFormState: useUserInfoFormType = {

    date_of_birth: '',
    gender: '',
    phone_number: '',
    whatsap_number: '',
    population_group: '',
    country: '',

    medication: [{ id: 'medication0', value: '' }],
    allergies: [{ id: 'allergies0', value: '' }],
    district: '',
    sub_county: '',
    village: '',


    e_full_name: '',
    e_relationship: '',
    e_phone_number: '',
    e_email: '',
    id: '',
    first_name: '',
    last_name: '',
    is_form_submited: false,
    otherGender: '', // Added this line to initialize otherGender


};



export default function useUserInfoForm() {
    // const dispatch = useDispatch();
    const [useUserInfoFormValues, setuseUserInfoFormValues] =
        React.useState<useUserInfoFormType>(initialuseUserInfoFormState);


    const [errors, setErrors] = React.useState<errorType>({});


    const handleChange = (e: any) => {

        const { name, value } = e.target;
       // alert(value)

        setErrors({ ...errors, ...{ [name]: "", username: "", invalid_code: '' } });

        setuseUserInfoFormValues((prevValues) => {
            // Create a copy of the previous state
            const updatedValues = { ...prevValues, [name]: value };
            return updatedValues;
        });
    };


    const handleMulipleChange = (e: any, index: number, nameprop: NameProp) => {
        const { value } = e.target;
        console.log(value)

        setErrors({ ...errors, ...{ [nameprop]: "", username: "", invalid_code: '' } });

        const newPropertData = [...useUserInfoFormValues[nameprop]];
        newPropertData[index] = { ...newPropertData[index], value, id: nameprop + index };

        setuseUserInfoFormValues(prevValues => ({
            ...prevValues,
            [nameprop]: [...newPropertData]
        }));

        console.log(useUserInfoFormValues)

    };

    const addInputField = (name: NameProp) => {



        const currentMultipleValues = useUserInfoFormValues[name];

        const newArrData = currentMultipleValues;


        newArrData.push({ value: '', id: name + (currentMultipleValues.length) });


        setuseUserInfoFormValues({
            ...useUserInfoFormValues,
            ...{
                property: newArrData
            }
        })


    };




    const setuseUserInfoFormErrors = (key: string, error: string) => {
        setErrors({ ...errors, ...{ [key]: error } });
    }


    const setFormSubmitStatus = (is_form_submitted: boolean) => {
        setuseUserInfoFormValues({ ...useUserInfoFormValues, ...{ is_form_submitted } });
    }


    const validateuseUserInfoForm = () => {
        const temp: errorType = {};
        var status = true;


        return true;
        if (!useUserInfoFormValues.first_name) {
            temp.first_name = "First name field is required";
            status = false;
        }

        if (!useUserInfoFormValues.population_group) {
            temp.population_group = "Key population field is required";
            status = false;
        }

        if (!useUserInfoFormValues.last_name) {
            temp.last_name = "Last name field is required";
            status = false;
        }

        if (!useUserInfoFormValues.date_of_birth) {
            temp.date_of_birth = "Date of Birth field is required";
            status = false;
        }


        if (!useUserInfoFormValues.gender) {
            temp.gender = "Gender field is required";
            status = false;
        }

        if (!useUserInfoFormValues.phone_number) {
            temp.phone_number = "Phone number field is required";
            status = false;
        }

        if (!useUserInfoFormValues.whatsap_number) {
            temp.whatsap_number = "whatsap number field is required";
            status = false;
        }


        if (!useUserInfoFormValues.country) {
            temp.country = "Country field is required";
            status = false;
        }

        if (!useUserInfoFormValues.district) {
            temp.district = "District field is required";
            status = false;
        }

        if (!useUserInfoFormValues.sub_county) {
            temp.sub_county = "Sub county field is required";
            status = false;
        }


        if (!useUserInfoFormValues.village) {
            temp.village = "Village field is required";
            status = false;
        }

        if (!useUserInfoFormValues.e_full_name) {
            temp.e_full_name = "Full name field is required";
            status = false;
        }

        if (!useUserInfoFormValues.e_relationship) {
            temp.e_relationship = "Relationship field is required";
            status = false;
        }
        if (!useUserInfoFormValues.e_phone_number) {
            temp.e_phone_number = "Phone number field is required";
            status = false;
        }

        if (!useUserInfoFormValues.medication[0].value) {
            temp.medication = "Medication field is required";
            status = false;
        }

        if (!useUserInfoFormValues.allergies[0].value) {
            temp.allergies = "Allergies field is required";
            status = false;
        }


        setErrors({ ...temp });

        return status;



    };

    const fillFormValuesInEditMode = (UserValue: useUserInfoFormType) => {
        setuseUserInfoFormValues({ ...UserValue })
    }


    const removeInputField = (name: NameProp, index: Number) => {



        const currentMultipleValues = useUserInfoFormValues[name];

        const newArrData = currentMultipleValues;
        // @ts-ignore

        newArrData.splice(index, 1)


        setuseUserInfoFormValues({
            ...useUserInfoFormValues,
            ...{
                property: newArrData
            }
        })


    };


    return {
        handleChange,
        validateuseUserInfoForm,
        errors,
        setErrors,
        useUserInfoFormValues,
        setuseUserInfoFormErrors,
        setFormSubmitStatus,
        handleMulipleChange,
        addInputField,
        fillFormValuesInEditMode,
        removeInputField
    };
}