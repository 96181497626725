import React, { useState } from "react";
import Register1 from "../../../../../components/Registration/Register1";
import Register2 from "../../../../../components/Registration/Register2";
import useForm from "hooks/useForm";
import RequestService from "services/requestService";
//import ToastMessage from "components/shared/ToastMesaage";
import EmailLinkVerification from "components/shared/EmailLinkVerification";


const PatientRegistration: React.FC = () => {
  const { handleChange, registerValues,
    setFormSubmitStatus,
    errors, setRegisterErrors, validateRegister } = useForm(
      true,
      "register"
    );
  const [step, setStep] = useState(1);

  const [success, setSuccess] = useState(false);
  const [emailResendError, setEmailResendError]=useState('');



  const [toastMessage, setToastMessage] = useState({
    status: '',
    message: ''
  });

  const [is_code_reset, setResend] = useState(false);



  const moveToNextStage = (stage: number) => {

    if (stage === 3) {
      saveUserData();

      return;
    }

    setStep(stage);

  }

  const saveUserData = () => {


    setFormSubmitStatus(true);

    const formBody = {
      ...registerValues, ...{ stage: 1 }
    }

    RequestService.postMethod('register', formBody)

      .then(async (response) => {
        const responseData = response.data.responseData;


        if (responseData) {
          setFormSubmitStatus(false);

          setSuccess(true);
          setStep(3);


        }


      })
      .catch(error => {

        setFormSubmitStatus(false);

        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setRegisterErrors('password', errorData?.responseData)
        }
        if (errorData?.errors) {
          const errors = errorData?.errors;
          // alert("yes")
          setErrorResponse(errors);
        }

      })
  }


  const setErrorResponse = (errors: any) => {

    for (let key in errors) {
      setRegisterErrors(key, errors[key][0]);
      if (key === 'phone_number') {
        setRegisterErrors('custom_error', errors[key][0]);
        setToastMessage({
          ...toastMessage, ...{
            status: 'error',
            message: errors[key][0]
          }
        })
      }

      if (key === 'username') {
        setRegisterErrors('custom_error', errors[key][0]);
        setToastMessage({
          ...toastMessage, ...{
            status: 'error',
            message: errors[key][0]
          }
        })
      }

    }
  }



  const resendEmailLink = () => {



    setErrorResponse({
      invalid_code: ['']
    });

    setResend(true);

    setEmailResendError('');

    const body = {
      email: registerValues?.email
    }

    RequestService.postMethod('resend-code', body)
      .then((response) => {
        const responseData = response.data.responseData;
        if (responseData) {
          // setStep(4);

        }
   
        setResend(false);

      })
      .catch(error => {
        setResend(false);
        const errorData = error?.response?.data;


        if (errorData?.responseData) {
          setEmailResendError(errorData.responseData)

         // setRegisterErrors('invalid_code', errorData?.responseData)
        }
        if (errorData?.status && errorData?.errors) {
          const errors = errorData.errors;
          setErrorResponse(errors);
        }

      })
  }

  return (
    <div className="w-full">

      {step === 1 && (
        <Register1
          onNext={() => moveToNextStage(2)}
          handleChange={handleChange}
          registerValues={registerValues}
          errors={errors}
          validateRegister={() => validateRegister(step)}
        />
      )}
      {step === 2 && (
        <Register2
          onBack={() => moveToNextStage(1)}
          onNext={() => moveToNextStage(3)}
          handleChange={handleChange}
          registerValues={registerValues}
          success={success}
          errors={errors}
          validateRegister={() => validateRegister(step)}
        />
      )}


      {step === 3 && success && (
        <EmailLinkVerification error={emailResendError} isSendActive={is_code_reset} resendEmail={() => resendEmailLink()} />
      )}




      {/* <ToastMessage message={toastMessage.message} status={toastMessage.status} /> */}



    </div>
  );
};

export default PatientRegistration;
