import BackNavigation from "components/shared/backNavigation";
import '../../../../assests/styles/request-lab-service.css';

export default function HivTestingPatientDetails() {
  return (
    <div className="w-screen h-screen">
      <div className="p-8 flex flex-col gap-4">
        <div>
          <BackNavigation goBack={() => "h"} title="Forgot something ?" />
        </div>
        <div className="w-[100%]">
          <h3 className="text-[30px] font-[500] text-[#000] topographyText flex gap-5">
            Well done, <p className="bg-text text-[30px] font-[500]">Mike</p>
          </h3>
          <p className="topographyText text-[15px] font-[400]">Now confirm if we have captured your information correctly</p>
        </div>
        <div className="flex flex-col gap-4 ">
         <div className="flex justify-between items-center ">
         <h3 className="text-[20px] md:text-[30px] font-[500] topographyText">Personal Details</h3>
         
         </div>
          <div>
            <section className="flex justify-between items-center">
              <p className="text-[15px] font-[500] topographyText">
                First Name
              </p>
              <p className="text-[15px] font-[400] topographyText">williams</p>
            </section>
            <section className="flex justify-between items-center">
              <p className="text-[15px] font-[500] topographyText">Last Name</p>
              <p className="text-[15px] font-[400] topographyText">sseruwagi</p>
            </section>
            <section className="flex justify-between items-center">
              <p className="text-[15px] font-[500] topographyText">Gender</p>
              <p className="text-[15px] font-[400] topographyText">Male</p>
            </section>
            <section className="flex justify-between items-center">
              <p className="text-[15px] font-[500] topographyText">District</p>
              <p className="text-[15px] font-[400] topographyText">Kampala</p>
            </section>
            <section className="flex justify-between items-center">
              <p className="text-[15px] font-[500] topographyText">Sub County</p>
              <p className="text-[15px] font-[400] topographyText">Gaba</p>
            </section>
          </div>
          <div>
            <div className="flex justify-between items-center">
            <h3 className="text-[20px] md:text-[30px] font-[500] topographyText">Contact Details</h3>
          
            </div>
            <div>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">Phone</p>
                <p className="text-[15px] font-[400] topographyText">+256 785049745</p>
              </section>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                  WhatsApp
                </p>
                <p className="text-[15px] font-[400] topographyText">+256 785049745</p>
              </section>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                  Email Address
                </p>
                <p className="text-[15px] font-[400] topographyText">San12williams@gmail.com</p>
              </section>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                  Physical Address
                </p>
                <p className="text-[15px] font-[400] topographyText">Ggaba Water</p>
              </section>
            </div>
          </div>
          <div>
          <div className="flex justify-between items-center">
          <h3 className="text-[20px] md:text-[30px] font-[500] topographyText">Reason for Testing </h3>
          
            </div>
           
            <div>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                  Type of Lab Test
                </p>
                <p className="text-[15px] font-[400] topographyText">HIV-Related Care</p>
              </section>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                Additional Information
                </p>
                <p className="text-[15px] font-[400] topographyText">Typed message from the user goes here.</p>
              </section>
            </div>
          </div>
          
          <div>
          <div className="flex justify-between items-center">
          <h3 className="text-[20px] md:text-[30px] font-[500] topographyText">Testing Preferences </h3>
      
            </div>
            
            <div >
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                Testing Mode
                </p>
                <p className="text-[15px] font-[400] topographyText">Lab Technician</p>
              </section>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                  Preferred date
                </p>
                <p className="text-[15px] font-[400] topographyText">MM/DD/YY</p>
              </section>
              <section className="flex justify-between items-center">
                <p className="text-[15px] font-[500] topographyText">
                  Preferred time
                </p>
                <p className="text-[15px] font-[400] topographyText">HH:MM AM/PM</p>
              </section>
              <section className="flex justify-between">
                <p className="text-[15px] font-[500] topographyText">
                Sample collection point
                </p>
                <p className="text-[15px] font-[400] topographyText">Home</p>
              </section>
              <section className="flex justify-between">
                <p className="text-[15px] font-[500] topographyText">
                Sample collection point
                </p>
                <p className="text-[15px] font-[400] topographyText">Lab Results Delivery Mode</p>
              </section>
            
            
            </div>
          </div>
         </div>

      <div className="flex flex-col gap-4">
        <p className="topographyText  text-[15px] text-center">By submitting this form, I consent to receive healthcare services via telemedicine and understand that my personal and medical information will be securely stored and used in accordance with privacy regulations.</p>
        <div className="flex justify-center">
        <button className=" bg-primary_color px-30 py-2 text-[#fff] font-[500] flex justify-center md:px-[250px] sm:px-30 ">
            submit
        </button>
        </div>
      </div>
      </div>
    </div>
    
  );
}
