const FirstDocScreen = () => {
    return (<div className="text-pink-800">


        First Doctor screen screen



    </div>)
}

export default FirstDocScreen;