import React, { useState } from 'react';
import Footer from 'layout/patient/footer';
import Header from 'layout/patient/header';
import MobileHeader, { MobileHeaderProps } from 'layout/patient/MobileHeader'; // Correct import path and MobileHeaderProps import

const defaultProfilePicture = '/default-profile-picture.png'; // Replace with your default profile picture URL

const AddAccountsMobile: React.FC = () => {
    const [fullName, setFullName] = useState('Your Name');
    const [email, setEmail] = useState('email@example.com');
    const [phoneNumber, setPhoneNumber] = useState('1234567890');
    const [country, setCountry] = useState('Country Name');
    const [district, setDistrict] = useState('District Name');
    const [village, setVillage] = useState('Village Name');
    const [dob, setDob] = useState('2000-01-01'); // Set to a valid date format
    const [profilePicture, setProfilePicture] = useState<string>(defaultProfilePicture); // Initialize with default profile picture

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicture(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSidebarOpen = () => {
        // Implement your sidebar open logic here
        console.log('Sidebar opened');
    };

    const handleLogout = () => {
        // Implement your logout logic here
        console.log('Logged out');
    };

    // Define props to pass to MobileHeader
    const mobileHeaderProps: MobileHeaderProps = {
        onOpenSidebar: handleSidebarOpen,
        onLogout: handleLogout,
    };

    return (
        <div className="md:hidden"> {/* Ensure component is hidden on large screens */}
            <Header />
            <div className="md:hidden">
                {/* Pass mobileHeaderProps to MobileHeader */}
                <MobileHeader {...mobileHeaderProps} />
            </div>
            <div className="mt-4 px-4">
                <div className="flex flex-col space-y-4">
                    <div className="bg-white rounded-xl shadow-md overflow-hidden w-full">
                        <div className="p-4 flex flex-col items-start space-y-4">
                            <div className="text-lg font-bold">Add Account Setting</div>
                            <button className="relative flex items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                <span className="mr-2">Profile</span>
                                <div className="absolute right-0 h-full bg-gray-400 w-1"></div>
                            </button>
                            <a href="/change-password" className="text-blue-500 hover:underline">Change my password</a>
                            <div className="ml-auto flex items-center space-x-4">
                                <img src={profilePicture} alt="Profile" className="w-10 h-10 rounded-full" />
                                <input type="file" onChange={handleFileChange} className="mt-2" />
                            </div>
                        </div>
                        <div className="p-4 border-t border-gray-200">
                            <div className="text-2xl font-bold mb-4">Profile Information</div>
                            <div className="mb-4">
                                <div className="text-lg font-bold mb-2">Full Name</div>
                                <input type="text" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" />
                            </div>
                            <div className="mb-4">
                                <div className="text-lg font-bold mb-2">Email Address</div>
                                <input type="email" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                            </div>
                            <div className="mb-4">
                                <div className="text-lg font-bold mb-2">Phone Number</div>
                                <input type="tel" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
                            </div>
                        </div>
                        <div className="p-4 border-t border-gray-200">
                            <div className="text-2xl font-bold mb-4">Address</div>
                            <div className="mb-4">
                                <div className="text-lg font-bold mb-2">Country</div>
                                <input type="text" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Country" />
                            </div>
                            <div className="mb-4">
                                <div className="text-lg font-bold mb-2">District</div>
                                <input type="text" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={district} onChange={(e) => setDistrict(e.target.value)} placeholder="District" />
                            </div>
                            <div className="mb-4">
                                <div className="text-lg font-bold mb-2">Village</div>
                                <input type="text" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={village} onChange={(e) => setVillage(e.target.value)} placeholder="Village" />
                            </div>
                        </div>
                        <div className="p-4 border-t border-gray-200">
                            <div className="text-2xl font-bold mb-4">Date of Birth</div>
                            <div className="mb-4">
                                <input type="date" className="border-gray-300 border-2 rounded-md px-3 py-2 w-full" value={dob} onChange={(e) => setDob(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AddAccountsMobile;
