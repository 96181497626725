/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import useStates from "hooks/useStates";
import { DateInput } from "./InputComponents/InputComponents";
import useRequestLabService from "hooks/useRequestLabService";

const PEPRequestDetails: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const PrepRequest = useSelector(
    (state: any) => state.hivCare.Pre_Exposure_Prophylaxis
  );
  useRequestLabService();
  useStates();


  // Form states
  const [selectedFile, setSelectedFile] = useState<File | null>(
    PrepRequest.report_file_uri ? PrepRequest.report_file_uri : null
  );
  const [preferredMedication] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    PrepRequest.result_delivery_method ? PrepRequest.result_delivery_method : ""
  );
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<string>("");
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<number>(
    PrepRequest.facility_id
  );
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>(
    PrepRequest.facility_id ? PrepRequest.facility_id : ""
  );
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<string>(
    PrepRequest.preferred_date ? PrepRequest.preferred_date : ""
  );
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<string>(
    PrepRequest.preferred_time ? PrepRequest.preferred_time : ""
  );
  const [testDate, setTestDate] = useState(
    PrepRequest.last_test_date ? PrepRequest.last_test_date : ["", "", ""]
  );
  const [selectedday, selectedmonth, selectedyear] = selectedDeliveryDate
    ? selectedDeliveryDate.split("-")
    : ["", "", ""];
    const [selectedTestDay, selectedTestMonth, selectedTestYear] = Array.isArray(testDate) 
    ? testDate 
    : testDate 
    ? testDate.split("-") 
    : ["", "", ""];
  
    const [selectedhour, selectedminutes] =selectedDeliveryTime? selectedDeliveryTime.split(/:| /):'';
  
  // Error states
  const [errors, setErrors] = useState({
    facility: false,
    deliveryMethod: false,
    deliveryDate: false,
    deliveryTime: false,
    testDate: false,
    selectedFiles: false,
  });

  // Tracks if the form has been submitted
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const dispatch = useDispatch();
  const { GetPrepExposeProphylaxis } = useHivCare();

  // Validate the form fields
  const validateForm = () => {
    const newErrors = {
      facility: !selectedFacilityId,
      deliveryMethod: !deliveryMethod,
      deliveryDate: selectedday === "" || selectedmonth === "" || selectedyear === "",
      deliveryTime: !selectedhour.length || !selectedminutes.length,
      testDate: selectedTestDay === "" || selectedTestMonth === "" || selectedTestYear === "",
      selectedFiles: selectedFile === null,
    };
  
    setErrors(newErrors);
  
    return Object.values(newErrors).every((error) => !error);
  };
  

  // Handle the "Next" button click
  const handleNext = () => {
    setHasSubmitted(true); // Set to true when user clicks "Next"

    if (validateForm()) {
      // If form is valid, proceed
      GetPrepExposeProphylaxis.PrePRequestDetails(
        dispatch,
        preferredMedication,
        deliveryMethod,
        selectedFacilityId,
        selectedDeliveryDate,
        selectedDeliveryTime,
        testDate,
        selectedFile
      );
      onNext();
    }
  };
 
  useEffect(() => {
    if (selectedFacilityId) {
      setErrors((prevErrors) => ({ ...prevErrors, facility: false }));
    }
    if (deliveryMethod) {
      setErrors((prevErrors) => ({ ...prevErrors, deliveryMethod: false }));
    }
    if (selectedday !== "" && selectedmonth !== "" && selectedyear !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, deliveryDate: false }));
    }
    if (selectedhour !== "" && selectedminutes !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, deliveryTime: false }));
    }
    if (selectedTestDay !== "" && selectedTestMonth !== "" && selectedTestYear !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, testDate: false }));
    }
    if (selectedFile) {
      setErrors((prevErrors) => ({ ...prevErrors, selectedFiles: false }));
    }
  }, [
    selectedFacilityId,
    deliveryMethod,
    selectedday,
    selectedmonth,
    selectedyear,
    selectedhour,
    selectedminutes,
    testDate,
    selectedFile,
  ]);
  
 
 
  // Handle field change and reset specific error
  const handleFieldChange = (field: string, value: any) => {
    // Reset error for the field when user interacts

    switch (field) {
      case "facility":
        setSelectedFacilityId(value);
        break;
      case "deliveryMethod":
        setDeliveryMethod(value);
        break;
      case "deliveryDate":
        setSelectedDeliveryDate(value);
        break;
      case "deliveryTime":
        setSelectedDeliveryTime(value);
        break;
      case "testDate":
        setTestDate(value);
        break;
      case "selectedFiles":
        setSelectedFile(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="request-lab-container">
      <h3 className="heading2">Your PreP Request Details</h3>

      <FacilitySelector
        selectedfacilityId={(value: string) =>
          handleFieldChange("facility", value)
        }
        id={selectedFacilityId}
      />
      {hasSubmitted && errors.facility && (
        <p className="error-message">Please select a facility.</p>
      )}

      <div className="section">
        <DateInput
          selectedTestDate={PrepRequest.last_test_date}
          setDate={setTestDate}
        />
        {hasSubmitted && errors.testDate && (
          <p className="error-message">
            Please select your last HIV test date.
          </p>
        )}

        <div className="mt-2">
          <h3 className="heading3">Upload recent HIV Test Report</h3>
          <input
            className="block w-full text-sm border border-white rounded-lg cursor-pointer bg-white focus:outline-none"
            id="file_input"
            type="file"
            onChange={(e) =>
              handleFieldChange(
                "selectedFiles",
                e.target.files ? e.target.files[0] : null
              )
            }
          />
          {hasSubmitted && errors.selectedFiles && (
            <p className="error-message">Please select your last HIV report.</p>
          )}
        </div>
      </div>

      <h3 className="heading3">Preferred Delivery Method</h3>
      <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] space-y-4">
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Home"
            checked={deliveryMethod === "Home"}
            onChange={() => handleFieldChange("deliveryMethod", "Home")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Home</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Workplace"
            checked={deliveryMethod === "Workplace"}
            onChange={() => handleFieldChange("deliveryMethod", "Workplace")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Workplace</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Health Facility"
            checked={deliveryMethod === "Health Facility"}
            onChange={() =>
              handleFieldChange("deliveryMethod", "Health Facility")
            }
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Health Facility</span>
        </label>
      </div>
      {hasSubmitted && errors.deliveryMethod && (
        <p className="error-message">Please select a delivery method.</p>
      )}

      <DeliveryDateTime
        setTime={(value: string) => handleFieldChange("deliveryTime", value)}
        setDate={(value: string) => handleFieldChange("deliveryDate", value)}
        selectedDate={PrepRequest.preferred_date}
        selectedTime={PrepRequest.preferred_time}
        
      />
      {hasSubmitted && errors.deliveryDate  ?  (
        <p className="error-message">Please select a delivery date.</p>
      ):errors.deliveryTime ? (
        <p className="error-message">Please select a delivery time.</p>
      ):false}
     

      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button type="submit" className="submit-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PEPRequestDetails;
