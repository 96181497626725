import React, { useState } from "react";
import "../../assests/styles/request-lab-service.css";
import { useDispatch, useSelector } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import Loader from "components/shared/Loader";
import useStates from "hooks/useStates";
import useRequestLabService from "hooks/useRequestLabService";
import { setEditSummary } from "Features/app/RequestLabService/HivCare.slice";

type ServiceType = {
  service: string;
};
const ConsentDatause: React.FC<ServiceType> = ({ service }) => {
  const prepData = useSelector(
    (state: any) => state.hivCare.Pre_Exposure_Prophylaxis
  );
  const HealthProductsData = useSelector(
    (state: any) => state.hivCare.Health_Products
  );
  const errors = useSelector((state: any) => state.hivCare.errors);
  const ArtData = useSelector(
    (state: any) => state.hivCare.Antiretroviral_Therapy
  );
  const MentalHeathData = useSelector(
    (state: any) => state.hivCare.Mental_Health_Services
  );
  const pepData = useSelector(
    (state: any) => state.hivCare.Post_Exposure_Prophylaxis
  );
  const loading = useSelector((state: any) => state.hivCare.createLoadings);
  //const isSuccess = useSelector((state: any) => state.hivCare.createSuccess);

  const [consent, setConsent] = useState(false);
  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.checked);
  };
  const {
    GetPrepExposeProphylaxis,
    GetHealthproducts,
    GetArtTreatment,
    GetMentalHealth,
    GetPEPServices,
  } = useHivCare();
  const dispatch = useDispatch();

  const { getFacilities_data } = useStates();
  const { lapServiseState } = useRequestLabService();
  const { LabTestDone, collectSampleFrom, getResultsFrom } =
    lapServiseState;
  let facilityId = null;
  for (let i = 0; i < getFacilities_data?.length; i++) {
    if (getFacilities_data[i].name === LabTestDone?.SeletedOption) {
      facilityId = getFacilities_data[i].id;
      break;
    }
  }
  const { GetHivTestService } = useHivCare();
  const requestLabServiceData = {
    facility_id: facilityId,
    additional_info: LabTestDone.AddedAdditionalInformation,
    preferred_date: LabTestDone.SeletedDateAndTime,
    preferred_time: LabTestDone.SeletedTime,
    sample_collection_location:collectSampleFrom,
    result_delivery_method: getResultsFrom,
    service_type: "HIV_S",
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (consent) {
      switch (service) {
        case "PREP_S":
          {
            const formData = prepData;
            GetPrepExposeProphylaxis.getRequest(dispatch, formData);
          }
          break;
        case "HEALTH_PRODUCT_S":
          const formData = HealthProductsData;
          GetHealthproducts.posthealthProducts(dispatch, formData);
          break;
        case "ANTIRE_THEREPY_S":
          {
            const formData = ArtData;
            GetArtTreatment.postArtForm(dispatch, formData);
          }
          break;
        case "MENTAL_HEALTH_S":
          {
            const formData = MentalHeathData;
            GetMentalHealth.PostMentalHealthForm(dispatch, formData);
          }
          break;
        case "PEP_S":
          {
            const formData = pepData;
            GetPEPServices.PostPepDetails(dispatch, formData);
          }
          break;
        case "HIV_S":
          GetHivTestService.postHivTestService(dispatch, requestLabServiceData);

          break;
        default:
          break;
      }
    }
  };

  const setEdit = () => {
    dispatch(setEditSummary(true));
  };

  return (
    <div className="request-lab-container w-full m-0">
      <label className=" mb-4 flex justify-start  gap-2">
        <input
          type="checkbox"
          checked={consent}
          onChange={handleConsentChange}
          className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        By submitting this form, I consent to the collection and use of my
        personal and medical information for service delivery.
      </label>

      <div className="flex gap-4">
        {!loading && (
          <button type="submit" className="submit-button" onClick={setEdit}>
            Back
          </button>
        )}
        <button
          type="submit"
          className={!consent ? "inactive" : "submit-button"}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <div className="flex justify-center items-center w-full h-full m-0">
              <Loader />
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </div>

      {errors && (
        <div>
          <ErrorComponent showError={errors} message={errors} />
        </div>
      )}
    </div>
  );
};

export default ConsentDatause;
