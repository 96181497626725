/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';


import RequestService from "services/requestService";
import { useLocation } from 'react-router-dom';

import WindowLoader from "components/shared/windowLoader";
import ToastMessage from "components/shared/ToastMesaage";
import { useDispatch } from 'react-redux';
import localStorageService from "services/localStorageService";
import { setIsAuthenticated, setUserInfo } from '../../../../Features/auth/auth.slice';
import { setSession } from "services/sessionManagement";


export default function VerifyEmailToken() {

    const navigate = useNavigate();
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState('')
    const { id, token } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const expires = searchParams.get('expires');
    const signature = searchParams.get('signature');
    const dispatch = useDispatch();




    const submitFormData = async (url: string) => {


        RequestService.getMethod(url)
            .then(async (response) => {

                const responseData = response.data.responseData;
                setMessage(responseData);

              

                setStatus('success');

                await localStorageService.setLocalStorageData('user', responseData);

               
                await dispatch(setUserInfo(responseData));
                await setSession('session')
                await dispatch(setIsAuthenticated(true));

               
                navigate('/add-details');
             
            })
            .catch(() => {

                setMessage('Something went wrong, please try again ');

                setStatus('error');

                // const errorData = error?.response?.data;


            });
    };


    useEffect(() => {
        localStorageService.clearStorage('user');
        dispatch(setUserInfo({}));
        dispatch(setIsAuthenticated(false));

        const url = `email/verify/${id}/${token}?expires=${expires}&signature=${signature}`;
        submitFormData(url)

    }, [])



    return (
        <div>
            <WindowLoader />
            <ToastMessage message={message} status={status} />

        </div>
    );
}
