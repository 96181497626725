import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { StepProps } from './stepsProps';
import '../../assests/styles/request-lab-service.css';

type Question = {
  text: string;
  id: number;
};

type QuestionsProps = {
  questions: Question[] | null;
};

const Questions: React.FC<StepProps & QuestionsProps> = ({ onNext, onNotEligible, questions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswer = (answer: boolean) => {
    if (answer === true) {
      onNext();
    } else {
      if (questions && currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        onNotEligible && onNotEligible();
      }
    }
  };

  return (
    <div className="question-container">
      <AnimatePresence mode='wait'>
        <motion.div
          key={currentQuestionIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
        >
          <p className='question-p'>
            {questions && questions[currentQuestionIndex].text}
          </p>
          <div className='yesNo-btn-container'>
            <button onClick={() => handleAnswer(true)}>Yes</button>
            <button onClick={() => handleAnswer(false)}>No</button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Questions;
