import React, { useState } from 'react';
import downloadIcon from '../../pages/auth/MyDocuments/download-icon.png';
import deleteIcon from '../../pages/auth/MyDocuments/delete-icon.png';
import threeDotsIcon from '../../pages/auth/MyDocuments/three-dots-icon.png';
import addIcon from '../../pages/auth/MyDocuments/add-icon.png';
import SearchComponent from 'components/shared/SearchComponent';


const MobileMyDocument: React.FC = () => {
  const [searchResults, setSearchResults] = useState<any[]>([]); // Replace 'any' with your actual result type
  const [searchError, setSearchError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (query: string) => {
    if (query.trim() === '') {
      setSearchError('Please enter a search term.');
      setSearchResults([]);
      return;
    }

    setLoading(true);
    setSearchError(null);

    try {
      // Replace '/api/search' with your actual search API endpoint
      const response = await fetch(`/api/search?query=${encodeURIComponent(query)}`);
      const data = await response.json();

      if (response.ok) {
        if (data.results && data.results.length > 0) {
          setSearchResults(data.results);
        } else {
          setSearchResults([]);
          setSearchError('No results found.');
        }
      } else {
        setSearchError(data.message || 'An error occurred while searching.');
      }
    } catch (error) {
      console.error('Search error:', error);
      setSearchError('An error occurred while searching.');
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = (documentName: string) => {
    console.log('Downloading:', documentName);
    window.location.href = `/download/${documentName}`;
  };

  const handleDeleteClick = (documentName: string) => {
    console.log('Deleting:', documentName);
    // Implement your delete logic here
  };

  const handleAddClick = () => {
    console.log('Add icon clicked');
    document.getElementById('fileInput')?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log('Uploading file:', file.name);
      // Implement your upload logic here
    }
  };

  const documentName = 'HIV testing results.pdf'; // Example document name, replace with actual document name

  return (
    <div className="bg-white rounded-lg mx-5 relative sm:hidden" style={{ marginTop: '-18%' }}>
      {/* My Documents section */}
      <div className="font-poppins text-15px font-semibold text-black mb-4">
        My Documents
      </div>

      {/* Search input section using SearchComponent */}
      <SearchComponent
        onSearch={handleSearch}
        searchResults={searchResults}
        searchError={searchError}
        loading={loading}
      />

      {/* Document list */}
      <div>
        {/* Document item */}
        <div className="mb-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input type="checkbox" className="w-[15px] h-[15px]" />
              <span className="font-poppins font-normal text-[15px] text-black ml-2">Select all</span>
            </div>
            <div className="ml-auto flex items-center">
              <img
                src={downloadIcon}
                alt="Download"
                className="w-[20px] h-[20px] cursor-pointer"
                onClick={() => handleDownloadClick(documentName)}
              />
              <img
                src={deleteIcon}
                alt="Delete"
                className="w-[20px] h-[20px] cursor-pointer ml-2"
                onClick={() => handleDeleteClick(documentName)}
              />
            </div>
          </div>
          <br />
          <div className="flex items-center mt-2">
            <input type="checkbox" className="w-[15px] h-[15px]" />
            <div className="ml-2">
              <div className="font-poppins font-semibold text-[15px] text-[#0A0A0B]">{documentName}</div>
              <div className="font-poppins font-normal text-[15px] text-black">Size: 23KB</div>
            </div>
            <div className="ml-auto flex-grow flex items-center space-x-8" style={{ marginLeft: '9%' }}>
              <div className="flex flex-col items-start">
                <div className="font-poppins font-normal text-[15px] text-black">Document Type</div>
                <div className="font-poppins font-semibold text-[15px] text-[#0A0A0B]">Lab Results</div>
              </div>
              <img
                src={threeDotsIcon}
                alt="Options"
                className="w-[15px] h-[2.67] cursor-pointer"
              />
            </div>
          </div>
          <br />
          <br />
          <hr className="border-t border-[#D9D9D9]" />
          {/* Repeat document items as needed */}
        </div>
      </div>

      {/* Add icon */}
      <div className="fixed bottom-4 right-4" style={{ marginBottom: '20%' }}>
        <img
          src={addIcon}
          alt="Add"
          className="w-[50px] h-[50px] cursor-pointer"
          onClick={handleAddClick}
        />
        <input
          type="file"
          id="fileInput"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default MobileMyDocument;
