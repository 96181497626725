import React, { ChangeEventHandler } from 'react';



interface InputFieldProps {
    value: string,

    title: string,
    placeholder: string,
    type: string,
    name: string,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    uniqueKey:string
}

const InputField: React.FC<InputFieldProps> = ({ value, title, uniqueKey, placeholder, name, type, handleChange }) => {


    return (<div className='mt-3'>
        <div>
            <label className='text-[15px] md:text-[18px] topographyText font-[400]'>{title}</label>
        </div>
        <div className='mt-2 mb-4'>
            <input
                type={type}
                value={value}
                onChange={(e) => handleChange(e)}
                key={uniqueKey}
                id={uniqueKey}
                placeholder={placeholder}
                name={name}
                style={{marginLeft:0}}
                className={`border border-[#d4d7e3] w-full p-3 rounded-lg focus:outline-none focus:border-[#8a3ffc] focus:ring-0 placeholder:font-normal ${name}`}

            />
        </div>
    </div>);


}

export default InputField;