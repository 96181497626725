import axios from "axios";
import patientConstants from "utils/constants";
import localStorageService from "./localStorageService";



const getToken = async () => {
    const UserToken = await localStorageService.getStorageData('user');

    if (UserToken !== null && UserToken.token) {
        return UserToken.token;
    }

    return '';
}

export const getGenderIdentity = (gender: string
) => {

    const gen = gender === 'M' ? 'Male' : (gender === 'F' ? 'Female' : gender)

    return gen;

}



const RequestService = {

    async postMethod(action: string, requestData: { [key: string]: any }) {


        const url = patientConstants.api_domain + action;

        let formData = new FormData();

        // append your data
        for (var key in requestData) {
            formData.append(key, requestData[key]);
        }



        const UserToken = await getToken();


        return axios.post(url, formData, {
            headers: {
                'Authorization': `Bearer ${UserToken}`
            }
        });
    },

    async getMethod(action: string) {


        const UserToken = await getToken();

        const url = patientConstants.api_domain + action;

        return axios.get(url, { headers: { 'Authorization': `Bearer ${UserToken}` } });
    },





};

export default RequestService;
