import React, { useState } from "react";
import "../../../../assests/styles/HPProductRequestDetails.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import { useDispatch, useSelector } from "react-redux";
import useHivCare from "../HivLabService/useHivCare";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import { conditionalRenderService } from "services/healthProductService";
const HPProductRequestDetails: React.FC<StepProps> = ({
  onNext,
  onPrevious,
}) => {

  const HealthProductsData = useSelector(
    (state: any) => state.hivCare.Health_Products
  );

  const [productsNeeded, setProductsNeeded] = useState<string[]>(HealthProductsData.products_needed ? HealthProductsData.products_needed : []);
  const [quantities, setQuantities] = useState<{
    [key: string]: number | "" | string;
  }>({
    Condoms: HealthProductsData.quantity_required_for_condoms,
    Lubricants: HealthProductsData.quantity_required_for_lubricants,
    Clean_Needles: HealthProductsData.quantity_required_for_clean_needles,
    STI_Treatment_Medications: HealthProductsData.quantity_required_for_sti_treatment,
    HIV_Self_Test_Kits: HealthProductsData.quantity_required_for_self_test_kits,
    Emergency_Contraceptives: HealthProductsData.quantity_required_for_emergency_concentraptive,
    Pain_Relief_Medication: HealthProductsData.quantity_required_for_pain_relief_medication,
    Other: HealthProductsData.quantity_required_for_condoms,
  });
  const [otherProduct, setOtherProduct] = useState<string>("");

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setProductsNeeded((prev) =>
      checked ? [...prev, name] : prev.filter((item) => item !== name)
    );
  };
  const dispatch = useDispatch();
  const { GetHealthproducts } = useHivCare();
  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    product: string
  ) => {
    const { value } = e.target;
    setQuantities((prev) => ({
      ...prev,
      [product]: value ? value : "",
    }));
  };

  const handleOtherProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtherProduct(value);
    setQuantities((prev) => ({
      ...prev,
      Other: value.toString(), // Update as a string
    }));
  };
  GetHealthproducts.HpProductsRequest(dispatch, productsNeeded, quantities);
  var displayError = false;
  const setError = () => {
    displayError = false;
    if (productsNeeded.length === 0) {
      displayError = true;
      return "Please select the products that you need";
    } else if (quantities.quantity_required_for_clean_needles === "") {
      displayError = true;
      return "Please select the quantities you want!";
    }

    displayError = false;
    return;
  };
  const errorMessage = setError();

  const checkedBoxStatus = (type: string) => {
    return productsNeeded.includes(type)
  }

  const conditionalRender = (property: string) => {

    return conditionalRenderService(property, productsNeeded)

  }

  return (
    <div className="product-request-details">
      <h2>Product Request Details</h2>

      <div className="section">
        <h3>Products Needed: (Select all that apply)</h3>
        <label>
          <input
            type="checkbox"
            name="Condoms"
            checked={checkedBoxStatus("Condoms")}
            onChange={handleCheckboxChange}
          />
          Condoms
        </label>
        <label>
          <input
            type="checkbox"
            name="Lubricants"
            checked={checkedBoxStatus("Lubricants")}
            onChange={handleCheckboxChange}
          />
          Lubricants
        </label>
        <label>
          <input
            type="checkbox"
            name="Clean Needles"
            checked={checkedBoxStatus("Clean Needles")}
            onChange={handleCheckboxChange}
          />
          Clean Needles
        </label>
        <label>
          <input
            type="checkbox"
            name="STI Treatment Medications"
            checked={checkedBoxStatus("STI Treatment Medications")}
            onChange={handleCheckboxChange}
          />
          STI Treatment Medications
        </label>
        <label>
          <input
            type="checkbox"
            name="HIV Self-Test Kits"
            checked={checkedBoxStatus("HIV Self-Test Kits")}
            onChange={handleCheckboxChange}
          />
          HIV Self-Test Kits
        </label>
        <label>
          <input
            type="checkbox"
            name="Emergency Contraceptives"
            checked={checkedBoxStatus("Emergency Contraceptives")}
            onChange={handleCheckboxChange}
          />
          Emergency Contraceptives
        </label>
        <label>
          <input
            type="checkbox"
            name="Pain Relief Medication"
            checked={checkedBoxStatus("Pain Relief Medication")}
            onChange={handleCheckboxChange}
          />
          Pain Relief Medication
        </label>
        <label>
          <input
            type="checkbox"
            name="Other"
            checked={checkedBoxStatus("Other")}
            onChange={handleCheckboxChange}
          />
          Other:
        </label>
        {checkedBoxStatus("Other") && (
          <input
            type="text"
            value={otherProduct}
            onChange={handleOtherProductChange}
            placeholder="Please specify"
          />
        )}
      </div>

      {productsNeeded && productsNeeded.length > 0 &&
        <div className="section">
          <h3>Quantity Required for Each Product:</h3>
          {Object.keys(quantities).map((product) => (

            conditionalRender(product) && <div className="quantity-field" key={product}>
              <label htmlFor={product}>{product ? product.replaceAll('_', ' ') : ''}</label>
              {product === "Other" ? (
                <input
                  type="text"
                  id={product}
                  value={quantities[product] as string}
                  onChange={(e) => handleQuantityChange(e, product)}
                  placeholder="Quantity"
                />
              ) : (
                <input
                  type="number"
                  id={product}
                  value={quantities[product] === "" ? "" : quantities[product]}
                  onChange={(e) => handleQuantityChange(e, product)}
                  placeholder="Quantity"
                />
              )}
            </div>

          ))}
        </div>
      }
      <div className="flex gap-2">
        <button type="button" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button
          type="button"
          className="submit-button"
          onClick={onNext}
          disabled={errorMessage ? true : false}
        >
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default HPProductRequestDetails;
