import patientRoute from "./patientRoutes";
import { Route } from "react-router-dom";
import { useSelector} from 'react-redux';

import type { RootState } from '../../Store'

const PatientRoutes = () => {




    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    var filteredRoute:any;

    if (!isAuthenticated) {
        filteredRoute = patientRoute.filter((item) => !item.isAuth )
      
    }

    if (isAuthenticated) {
  
        filteredRoute = patientRoute.filter((item) => item.isAuth )
    }

  

   return   filteredRoute.map((item:any) => <Route path={item.path} key={item.path} element={<item.component />} />)
}

export default PatientRoutes;