import { Routes } from "react-router-dom";


import PatientRoutes from "../../../../src/routes/patient/patients";
// const people = "people"


function NetLifePatientApp() {
  return (
    <Routes>
      {PatientRoutes()}
      
    </Routes>
  );
}

export default NetLifePatientApp;
