import React, {  } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assests/styles/PersonalDataUpdateReminder.css';
import { useDispatch } from 'react-redux';
import { setIsAllowed } from 'Features/auth/auth.slice';
interface Props {
  personalDataCompleted: boolean;
  isNotAllowed: boolean;
}

const PersonalDataUpdateReminder: React.FC<Props> = ({isNotAllowed,personalDataCompleted}) => {
 
 const dispatch = useDispatch()
  const navigate = useNavigate();
console.log(personalDataCompleted,isNotAllowed)
  const handleUpdateNow = () => {
    navigate('/userform'); // Adjust the route as needed
    dispatch(setIsAllowed(true))
  };

  const handleRemindMeLater = () => {
  dispatch(setIsAllowed(true))
  };

  return (
    <>
      {!isNotAllowed && (
        <div className="personal-update-popup-container">
          <div className="personal-update-popup">
            <div className="personal-update-title">Complete Your Profile</div>
            <div className="personal-update-message">
              Your profile isn't fully up-to-date. Updating your personal data helps us provide you with more personalized support and access to all features. Take a moment to update your information now.
            </div>
            <div className="personal-update-button-group">
              <button className="personal-update-button" onClick={handleUpdateNow}>Update Now</button>
              <button className="personal-remind-me-later-button" onClick={handleRemindMeLater}>Remind Me Later</button>
            </div>
            <div className="personal-thank-you-message">
              This reminder will help us deliver the best experience tailored to you. Thank you for helping us improve your experience.
            </div>
          </div>
        </div>
      )}
      {/* {showWarning && (
        <div className="personal-update-popup-container">
          <div className="personal-update-popup">
            <div className="personal-warning-message-title"><strong>Important: Update pending.</strong></div>
            <div className="personal-warning-message-content">
              You opted to update your profile later. Please note that some features may be limited until your information is current. We'll check in with you again shortly to complete the update.
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default PersonalDataUpdateReminder;
