import React from "react";
import "../../../src/assests/styles/HivServeLinks.css";
import ArrowIcons from "../../assests/icons/Right.svg"
import { useNavigate } from "react-router-dom";
import {
  setRedirectService,
} from "Features/app/RequestLabService/HivCare.slice";
import { useDispatch } from "react-redux";
import HivLabTestData from '../../views/patients/pages/HivLabService/HivLabTestdata.json';
import useRequestLabService from "hooks/useRequestLabService";
const HivServeLinks: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch: dispa, setSeletedLabTest } = useRequestLabService();
  const services: any = HivLabTestData.labServices.map((service: any) => ({
    id: service.id,
    text: service.name,
  }));



  const dispatch = useDispatch();

  const handleSubmit = (service: any) => {



    dispatch(setRedirectService(service));
    dispa(setSeletedLabTest(service.text));

    //e.preventDefault()
    navigate('/hiv-care')
  }
  return (
    <div className="hiv-serve-links-section">

      <div className="hiv-serve-links-container">
        {services.map((service: any, index: any) => (
          <div className="hiv-serve-card" key={index}>
            {/* <FaArrowRight className="hiv-serve-icon" /> */}
            <img src={ArrowIcons} alt=" click arrow" />
            <p onClick={() => handleSubmit(service)}> {service.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HivServeLinks;
