
import HeaderTitle from "components/shared/HeaderTitle";
import BackNavigation from "components/shared/backNavigation";
import ViewTab from "./viewTab";



interface SecondStageProp {

    setFormData: Function,
    goBack: Function

}

const SecondStage: React.FC<SecondStageProp> = ({ goBack }) => {



    return (
    <div className="">

        <BackNavigation goBack={() => goBack()} title="Back to search" />

        <HeaderTitle title="We were able to find 2 Pharmacies based on your search" unit={25} />


        <ViewTab active="View" onClick={()=>{}}/>



    </div>)
}

export default SecondStage;