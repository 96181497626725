/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import { DateInput } from "./InputComponents/InputComponents";

const PEPRequestDetails: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const PrepRequest = useSelector(
    (state: any) => state.hivCare.Post_Exposure_Prophylaxis
  );

  const [preferredMedication] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(
    PrepRequest.report_file_uri ? PrepRequest.report_file_uri : ''
  );
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    PrepRequest.result_delivery_method ? PrepRequest.result_delivery_method : ""
  );
  const [facilityStatus] = useState<string>("");
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<string>("");
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>(
    PrepRequest.facility_id ? PrepRequest.facility_id : ""
  );
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<string>("");
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<string>("");
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<number>(PrepRequest.facility_id);
  const [testDate, setTestDate] = useState(
    PrepRequest.last_test_date ? PrepRequest.last_test_date : ["", "", ""]
  );
  const [selectedday, selectedmonth, selectedyear] = selectedDeliveryDate
  ? selectedDeliveryDate.split("-")
  : ["", "", ""];
  const [selectedTestDay, selectedTestMonth, selectedTestYear] = Array.isArray(testDate) 
  ? testDate 
  : testDate 
  ? testDate.split("-") 
  : ["", "", ""];

  const [selectedhour, selectedminutes] =selectedDeliveryTime? selectedDeliveryTime.split(/:| /):'';
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    facility: false,
    deliveryMethod: false,
    deliveryDate: false,
    deliveryTime: false,
    testDate: false,
    selectedFiles: false,
  });

  const dispatch = useDispatch();
  const { GetPEPServices } = useHivCare();


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };


  const validateForm = () => {
    const newErrors = {
      facility: !selectedFacilityId,
      deliveryMethod: !deliveryMethod,
     deliveryDate: selectedday === "" || selectedmonth === "" || selectedyear === "",
      deliveryTime: !selectedhour.length || !selectedminutes.length,
      testDate: selectedTestDay === "" || selectedTestMonth === "" || selectedTestYear === "",
      selectedFiles: selectedFile === null,
    };
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  };

  useEffect(() => {
    if (hasSubmitted) {
      validateForm();
    }
  }, [selectedFacilityId, deliveryMethod, selectedDeliveryDate, testDate, selectedFile, selectedday,
    selectedmonth,
    selectedyear,
    selectedhour,
    selectedminutes,]);

  const handleNext = () => {
    setHasSubmitted(true);
    if (validateForm()) {
      GetPEPServices.pepPreferences(
        dispatch,
        preferredMedication,
        deliveryMethod,
        facilityStatus,
        selectedFacilityId,
        selectedDeliveryDate,
        selectedDeliveryTime,
        testDate,
        selectedFile
      );
      onNext();
    }
  };

  return (
    <div className="request-lab-container">
      <h3 className="heading3">PEP Request Details</h3>

      <FacilitySelector selectedfacilityId={setSelectedFacilityId} id={selectedFacilityId} />
      {errors.facility && hasSubmitted && (
        <p className="error-message">Please select a facility.</p>
      )}

      <div className="section">
        <DateInput selectedTestDate={PrepRequest.last_test_date} setDate={setTestDate as any} />
        {errors.testDate && hasSubmitted && (
          <p className="error-message">Please select your last HIV test date.</p>
        )}
      </div>

      <div className="mt-2">
        <h3 className="heading3">Upload recent HIV Test Report</h3>
        
        <div className="mb-5">
          <input
            className="block w-full text-sm border border-white rounded-lg cursor-pointer bg-white focus:outline-none"
            id="file_input"
            type="file"
            onChange={handleFileChange}
          />
          {errors.selectedFiles && hasSubmitted && (
            <p className="error-message">Please select your last HIV report.</p>
          )}
        </div>
      </div>

      <h3 className="heading3">Preferred Delivery Method</h3>
      <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] space-y-4">
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Home"
            checked={deliveryMethod === "Home"}
            onChange={() => setDeliveryMethod("Home")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Home</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Workplace"
            checked={deliveryMethod === "Workplace"}
            onChange={() => setDeliveryMethod("Workplace")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Workplace</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Health Facility"
            checked={deliveryMethod === "Health Facility"}
            onChange={() => setDeliveryMethod("Health Facility")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Health Facility</span>
        </label>
      </div>
      {errors.deliveryMethod && hasSubmitted && (
        <p className="error-message">Please select a delivery method.</p>
      )}

      <DeliveryDateTime
        setTime={setSelectedDeliveryTime}
        setDate={setSelectedDeliveryDate}
        selectedDate={PrepRequest.preferred_date}
        selectedTime={PrepRequest.preferred_time}
      />
    {hasSubmitted && errors.deliveryDate  ?  (
        <p className="error-message">Please select a delivery date.</p>
      ):errors.deliveryTime ? (
        <p className="error-message">Please select a delivery time.</p>
      ):false}

      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button type="submit" className="submit-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PEPRequestDetails;
