import React, { useState } from 'react';
import viewPrintImage from '../../../pages/auth/MyDocuments/View.png';
import industryArrow from '../../../pages/auth/MyDocuments/arrow.png';

import Header from "layout/patient/header";
import MedicalHistoryMobile from '../medicalhistory'
import MobileHeader from 'layout/patient/MobileHeader';

const MedicalHistory: React.FC = () => {
  const [, setIsSidebarOpen] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [fullName, setFullName] = useState('Florence');
  const [dateOfBirth, setDateOfBirth] = useState('DD/MM/YYYY');
  const [gender, setGender] = useState('Female');
  const [keyPopulationGroup, setKeyPopulationGroup] = useState('female');

  const toggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(true);
  };


  const handleLogout = () => {
    console.log('Logged out');
    // Add actual logout logic here
  };

  const handleViewAndPrint = () => {
    // Implement logic to handle view and print functionality
    console.log('View and print medical history');
    // Add your logic here to initiate printing or viewing
  };

  return (
    <div>
      <Header />
      
      <div className="block md:hidden">
        <MedicalHistoryMobile />
        <MobileHeader onOpenSidebar={handleSidebarOpen} onLogout={handleLogout} />
      </div>
      
      <div className="p-6 max-w-3xl mx-auto hidden md:block" style={{ marginLeft: '9%', marginRight: '10%', marginTop: '-8%' }}>
      
        <h1 className="font-poppins text-[25] font-medium text-black">Medical History</h1>
        <div className="font-poppins text-[25] font-medium"> 
          <span className="text-[#8A3FFC]">Hi, Mike</span>
        </div>
        <p className="font-poppins text-[15px] font-normal text-black mt-4">
          Keeping your medical history updated is crucial for optimal healthcare.
        </p>

        {/* View and print medical history */}
        <div className="flex items-center mt-6 cursor-pointer" onClick={handleViewAndPrint}>
          <img src={viewPrintImage} alt="View and print your medical history" className="w-[30px] h-[30px] mr-2" />
          <span className="font-poppins text-[15px] font-medium text-[#8A3FFC]">View and print your medical history</span>
        </div>

          {/* Personal Information Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Personal Information</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* The Basics Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[25px] h-[25px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">The Basics</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Current Medications Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Current Medications</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Allergies Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Allergies</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Chronic Conditions Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Chronic Conditions</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Past Medical Events Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Past Medical Events</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Test Results Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Test Results</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Family Medical History Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Family Medical History</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          
          {/* Lifestyle and Social History Card */}
          <div className="industry-cards-unopened mt-2">
            <div className="flex items-center cursor-pointer" onClick={toggleCard}>
              {/* <div className="expert-icon-left mr-2">
            <img src={financeIcon} className="icon-industry w-[40px] h-[40px]" alt="service icon" />
          </div> */}
              <div className="industry-cards-content flex-grow">
                <h5 className="font-poppins text-[15px] font-medium">Lifestyle and Social History</h5>
              </div>
              <div className={`expertise-left-icon transform transition-transform ${isCardOpen ? 'rotate-180' : ''}`}>
                <img src={industryArrow} className="w-[15px]" alt="Toggle Arrow" />
              </div>
            </div>
            {isCardOpen && (
              <div className="p-4">
                <div className="p-8">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Full Name</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Date of Birth</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Gender</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder="Gender"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-2">Key Population Group</div>
                    <input
                      type="text"
                      className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                      value={keyPopulationGroup}
                      onChange={(e) => setKeyPopulationGroup(e.target.value)}
                      placeholder="Key Population Group"
                    />
                  </div>

                </div>

              </div>

            )}
          </div>
          {/* Button with specified styles */}
          <button
            className="absolute w-643 h-89 top-1519 left-658 gap-0 rounded-[10px] opacity-100 bg-[#8A3FFC] text-white font-poppins text-[25px] font-semibold"
            style={{
              fontFamily: 'Poppins',
              fontSize: '15px',
              fontWeight: 500,
              lineHeight: '37.5px',
              textAlign: 'left',
              color: '#FFFFFF',
              width: '47%',
              paddingLeft: '22%',
              marginLeft: '4%',
              marginTop: '5%',

            }}
          >
            Save
          </button>
        </div>
        {/* <Footer /> */}
      </div>

    </div>
  );
};

export default MedicalHistory;
