import React, { useState, ChangeEvent } from 'react';
import searchIcon from '../../medicalhistoryImage/search-icon.png';

interface SearchComponentProps {
    onSearch: (query: string) => void;
    searchResults: any[]; // Consider specifying a more precise type if possible
    searchError: string | null;
    loading: boolean;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onSearch, searchError, loading }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchClick = () => {
        if (searchTerm.trim() === '') {
            return;
        }
        onSearch(searchTerm);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="relative p-4 mt-0">
            <div className="flex items-center justify-between mb-4">
                <h1 className="font-poppins font-medium text-2xl text-black">My Document</h1>
                <div className="flex items-center mb-4 border-4 rounded-lg w-3/5" style={{ borderColor: '#17bdb9' }}>
                    <input
                        type="text"
                        placeholder="Search By Document Name or Shared by"
                        className="font-poppins text-sm w-full h-12 px-4 py-2 border-none outline-none"
                        value={searchTerm}
                        onChange={handleInputChange}
                        aria-label="Search input"
                    />
                    <button
                        className="ml-2 p-2 bg-transparent border-none cursor-pointer"
                        onClick={handleSearchClick}
                        aria-label="Search"
                    >
                        <img
                            src={searchIcon}
                            alt="Search Icon"
                            className="w-4 h-4"
                        />
                    </button>
                </div>
            </div>
            {loading && <div className="text-blue-500 mb-4">Loading...</div>}
            {searchError && <div className="text-red-500 mb-4">{searchError}</div>}
        </div>
    );
};

export default SearchComponent;
