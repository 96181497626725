/* eslint-disable import/no-anonymous-default-export */



const getStorageData = async (key: string) => {


    const data = localStorage.getItem(key);

    if (data !== null) {
        return JSON.parse(data);
    }

    return null;

}

const setLocalStorageData = async (key: string, data: object) => {


    try {
        localStorage.setItem(key, JSON.stringify(data));


        return true;
    }
    catch (error) {
      //  alert(JSON.stringify(error))
    }
}

const clearStorage=async(key:string)=>{

    await localStorage.removeItem(key);


}

export default {
    getStorageData,
    setLocalStorageData,
    clearStorage
}