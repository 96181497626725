import { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import axios from "axios";
import { Location } from "./types";
import {
  setCollectSampleFrom1,
  setGetResultsFrom,
  setSeletedLabTest,
} from "Features/app/RequestLabService/requestLabServices.slice";
export default function useRequestLabService() {
  //states
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const lapServiseState = useSelector((state:any)=>state.requestLabService)
  
  const [typeOfLabService, setTypeOfLabService] = useState<any>();
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
   
    }
  }, []);
  const dispatch = useDispatch();

  async function success(position: GeolocationPosition) {
    const { latitude, longitude } = position.coords;
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDg7FC_Xq-sW7TjdkNBgqxX7iIHPGS92Dw`
      );
      console.log(response.data);
      const addressComponents = response?.data?.results[0]?.address_components;
      const city =
        addressComponents.find((component: any) =>
          component.types.includes("locality")
        )?.long_name || "Unknown City";
      const area =
        addressComponents.find((component: any) =>
          component.types.includes("sublocality_level_1")
        )?.long_name || "Unknown Area";

      setUserLocation({ city, area });
      console.log(`City: ${city}, Area: ${area}`);
    } catch (error) {
      console.error("Error fetching location data", error);
    }
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  return {
    userLocation,
    setTypeOfLabService,
    typeOfLabService,
    setCollectSampleFrom1,
    setGetResultsFrom,
    setSeletedLabTest,
    dispatch,
    lapServiseState
  };
}
