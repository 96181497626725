/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import SelectDropdown from "./selectmedcenter";
import TextInput from "./TextInput";
import { StepProps } from "./stepsProps";
import useRequestLabService from "hooks/useRequestLabService";
import "../../assests/styles/request-lab-service.css";
import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
import {
  setLabTestDoneDateAndTime,
  setLabTestDoneTime,
  setLabTestDoneOption,
} from "Features/app/RequestLabService/requestLabServices.slice";

type RequestLabServiceActions = {
  title: string | null;
  date_title: string | null;
  additional_Info: string | null;
};

const ServicesPreferences: React.FC<StepProps & RequestLabServiceActions> = ({
  onNext,
  onPrevious,
  title,
}) => {
  const hivTest = useSelector((state: any) => state.requestLabService);
  const dispatch = useDispatch<any>();

  const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<string | null>(null);
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});


  const [month, setMonth] = useState<string>("");
  const [day, setDay] = useState<number | string>("");
  const [year, setYear] = useState<number | string>(new Date().getFullYear());
  const [hour, setHour] = useState<number | string>("");
  const [minute, setMinute] = useState<number | string>("");
  const [period, setPeriod] = useState<string>("");


  const { lapServiseState } = useRequestLabService();
  const { LabTestDone } = lapServiseState;





  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    if (!selectedFacility) newErrors.selectedFacility = "Please select a facility.";
    if (!month || !day || !year) newErrors.selectedDate = "Please select a date.";
    if (!hour || !minute || !period) newErrors.selectedTime = "Please select a time.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextPage = () => {
    if (validateFields()) {
      const formattedDate = `${month} ${day}, ${year}`;
      const formattedTime = `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
      dispatch(setLabTestDoneOption(selectedFacility));
      dispatch(setLabTestDoneDateAndTime(formattedDate));
      dispatch(setLabTestDoneTime(formattedTime));
      onNext();
    }
  };


  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getNextTwoMonths = () => {
    const currentMonthIndex = new Date().getMonth();

    // Calculate the next two months
    const nextMonth1 = months[(currentMonthIndex + 0) % 12];
    const nextMonth2 = months[(currentMonthIndex + 1) % 12];

    return [nextMonth1, nextMonth2];

  }



  const setDateAndTime = () => {

    const formattedDate = LabTestDone.SeletedDateAndTime;
    const formattedTime = LabTestDone.SeletedTime;

    if(formattedDate){
    const dateParts = formattedDate.split(" ");  // ["October", "12,", "2024"]
    const month = dateParts[0];
    const day = parseInt(dateParts[1].replace(",", ""));
   // const year = parseInt(dateParts[2]);
    setMonth(month);
    setDay(day);
    setYear(new Date().getFullYear());
    }

    // Reversing formattedTime
    if(formattedTime){
    const timeParts = formattedTime.split(/[: ]/);  // ["10", "05", "AM"]
    const hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);
    const period = timeParts[2];
    setHour(hour);
    setMinute(minute);
    setPeriod(period)
    }


  }

  const handleFacilityChange = (facility: string | null) => {
    setSelectedFacility(facility);
    dispatch(setLabTestDoneOption(facility));
    if (facility) setErrors(prev => {
      const { selectedFacility, ...rest } = prev;
      return rest; // Remove the selectedFacility error
    });
  };

  const handleDateChange = (m: string, d: number | string, y: number | string) => {
    setMonth(m);
    setDay(d);
    setYear(y);
    if (m && d && y) setErrors(prev => {
      const { selectedDate, ...rest } = prev;
      return rest; // Remove the selectedDate error
    });
  };

  const handleTimeChange = (h: number | string, m: number | string, p: string) => {
    setHour(h);
    setMinute(m);
    setPeriod(p);
    if (h && m && p) setErrors(prev => {
      const { selectedTime, ...rest } = prev;
      return rest; // Remove the selectedTime error
    });
  };

  useEffect(() => {
    dispatch(getFacilities());
    setDateAndTime();
  }, []);

  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something?
        </div>
      </div>

      <div className="heading2">{title || null}</div>
      <p>Choose the lab facility that best suits your needs.</p>
      <SelectDropdown
        value={hivTest.LabTestDone.SeletedOption || selectedFacility}
        onChange={handleFacilityChange}
      />
      {errors.selectedFacility && <p className="error">{errors.selectedFacility}</p>}

      <div className="heading4 delivery-date-time">Date and Time</div>
      <div className="delivery-date-time section">
        <h3>What is your preferred date?</h3>
        <div className="date-selectors">
        <div className="input-group">
            <label>Year</label>
            <select value={year}
            disabled
            onChange={(e) => handleDateChange(month, day, Number(e.target.value))}>
              <option value="">Select Year</option>
              {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map(y => (
                <option key={y} value={y}>{y}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Month</label>
            <select value={month} onChange={(e) => handleDateChange(e.target.value, day, year)}>
              <option value="">Select Month</option>
              {getNextTwoMonths().map(m => (
                <option key={m} value={m}>{m}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Day</label>
            <select value={day} onChange={(e) => handleDateChange(month, Number(e.target.value), year)}>
              <option value="">Select Day</option>
              {Array.from({ length: new Date(Number(year), new Date(`${month} 1, ${year}`).getMonth() + 1, 0).getDate() }, (_, i) => i + 1).map(d => (
                <option key={d} value={d}>{d}</option>
              ))}
            </select>
          </div>
         
        </div>
      </div>

      <div className="delivery-date-time section">
        <h3>What is your preferred time?</h3>
        <div className="time-selectors">
          <div className="input-group">
            <label>Hour</label>
            <select value={hour} onChange={(e) => handleTimeChange(Number(e.target.value), minute, period)}>
              <option value="">Select Hour</option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map(h => (
                <option key={h} value={h}>{h}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Minute</label>
            <select value={minute} onChange={(e) => handleTimeChange(hour, Number(e.target.value), period)}>
              <option value="">Select Minute</option>
              {Array.from({ length: 60 }, (_, i) => i).map(m => (
                <option key={m} value={m}>{m.toString().padStart(2, "0")}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>AM/PM</label>
            <select value={period} onChange={(e) => handleTimeChange(hour, minute, e.target.value)}>
              <option value="">Select Period</option>
              {["AM", "PM"].map(p => (
                <option key={p} value={p}>{p}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {errors.selectedDate && <p className="error">{errors.selectedDate}</p>}
      {errors.selectedTime && <p className="error">{errors.selectedTime}</p>}

      <div className="heading4">Additional Information</div>
      <TextInput
        value={hivTest.LabTestDone.AddedAdditionalInformation || additionalInfo}
        onChange={setAdditionalInfo}
      />

      <div className="flex gap-4">
        <button type="button" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button type="button" className="submit-button" onClick={handleNextPage}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ServicesPreferences;
