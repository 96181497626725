import React from "react";
import GetStarted from "../../assests/icons/Getstarted.svg";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import useStates from "hooks/useStates";
import { useNavigate } from "react-router-dom"; // Import Link
import { useDispatch } from "react-redux";
import { setIsAllowed } from "Features/auth/auth.slice";

type WelcomePageTypes = {
  title: string | null;
  locationSubTitle: string | null;
};

const ServiceWelcomePage: React.FC<StepProps & WelcomePageTypes> = ({
  onNext,
  title,
  locationSubTitle,
}) => {
  const dispatch = useDispatch();
  const { first_name, isUserInfoCompleted } =
    useStates();
  const navigate = useNavigate();

  const handleAddLocationClick = () => {
    navigate("/add-details");
  };
 
  const handleClick = (e: any) => {
    e.preventDefault();
    if (!isUserInfoCompleted()) {
      dispatch(setIsAllowed(false));
      return;
    }
    onNext();
  };

  return (
    <div className="request-lab-container">
      <div className="welcome">Welcome, {first_name || null}</div>
      <div>
        <p>{title || null}</p>
      </div>
      <div className="heading2">Confirm your user data</div>
      <p>
        {locationSubTitle}. <span className="cursor-pointer" onClick={handleAddLocationClick}>Update Here</span>
      </p>
      <div className="location-card">
        <div>
        </div>
         <div className="center-button">
          <button onClick={handleClick} className="get-started">
          Get Started <img src={GetStarted} alt="get started" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceWelcomePage;
     
