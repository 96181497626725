/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../../../assests/styles/FacilitySelector.css';
import useStates from 'hooks/useStates';

type FacilitySelectorProps = {
  selectedfacilityId: (id: any) => void;
  id?: any
};

const FacilitySelector: React.FC<FacilitySelectorProps> = ({ selectedfacilityId, id = '' }) => {
  const [selectedFacility, setSelectedFacility] = useState<string>(id);
  const { getFacilities_data } = useStates();

  // Ensure getFacilities_data is always an array
  const facilities = Array.isArray(getFacilities_data) ? getFacilities_data : [];


  const handleFacilityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const facility = event.target.value;
    // alert(JSON.stringify(facility))
    setSelectedFacility(facility);
    selectedfacilityId(facility)
    // update parent state with selected facility id
  };

  useEffect(() => {
    if (!id) {
      setSelectedFacility('')
    }
    //alert(id)

  }, [])

  return (
    <div className="facility-selector-container">
      <label htmlFor="facility" className="facility-label">Select your preferred Facility</label>
      <select
        id="facility"
        className="facility-select"
        value={selectedFacility}
        onChange={handleFacilityChange}
      >
        <option key={''} value='' disabled>Select a facility</option>
        {facilities.map((center: any) => (
          <option key={center.id} value={center.id}>{center.name}</option>
        ))}
      </select>
    </div>
  );
};

export default FacilitySelector;
