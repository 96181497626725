import SeeDoctorComp from "../../../../components/seeDoctor";
import Footer from "../../../../layout/patient/footer";
import Header from "../../../../layout/patient/header";


const SeeDoctor = () => {
    return (<div>
        <Header />

        <SeeDoctorComp />
        

        <Footer />

    </div>)
}

export default SeeDoctor;