import React from 'react';



interface Title {


    title: string,
    unit?: number

}

const HeaderTitle: React.FC<Title> = ({ title, unit }) => {


    return (

        <div className='mb-14 mt-10'>
            <label className={`text-[${unit ? unit + 'px' : 45 + 'px'}]`}>{title}</label>
        </div>

    );


}

export default HeaderTitle;