import Header from "./header";
import LargeScreenNavBar from "./LargeScreenNavBar";
import NetLifePatientApp from "../../views/patients/pages/patientApp";
import "../../assests/styles/main_styles.css"
import WarningModal from "components/shared/warningModal";
import { useSelector } from 'react-redux';
import PersonalDataUpdateReminder from "components/shared/PersonalDataUpdateReminder";
import useStates from "hooks/useStates";
const AuthenticatedPatient = () => {
    const isAllowed = useSelector((state: any) => state.auth.isAllowed);
    const {isUserInfoCompleted} = useStates()


    return (

        <div className="w-full">
            <WarningModal />
            
            <PersonalDataUpdateReminder personalDataCompleted={isUserInfoCompleted()} isNotAllowed={isAllowed} /> 
            <Header />
            <div className='flex w-full'>
                <LargeScreenNavBar />

                <div className="content-container" >
                    <NetLifePatientApp />
                </div>
            </div>
        </div>
    )
}

export default AuthenticatedPatient;