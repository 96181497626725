import HeaderTitle from "components/shared/HeaderTitle";
import CheckBox from "components/shared/checkbox";
import FieldLabelText from "components/shared/fieldLabel";
import InputField from "components/shared/inputField";
import { useState } from "react";
import '../../assests/styles/find_service.css'



interface FirstStageProp {

   setFormData:Function
   
}

const FistStage:React.FC<FirstStageProp> = ({setFormData}) => {
    const [formData, setValue] = useState({
        within_distance: '',
        around_location: '',
        checkBox: {
            health_fac: '',
            pharmacies: ''
        }
    });

    const setInputField = (val: string, prop: string) => {
        setValue({ ...formData, [prop]: val })
        setFormData({ ...formData, [prop]: val })
    }



    const selectCheckBox = (property: string, value: string) => {

        formData.checkBox = {
            ...{
                health_fac: '',
                pharmacies: ''
            }
        }

        const checkBox = formData.checkBox = {
            health_fac: property === 'health_fac' && !formData.checkBox.health_fac ? value : '',
            pharmacies: property === 'pharmacies' && !formData.checkBox.pharmacies ? value : '',

        }

        const formRecords = {
            ...formData, ...{ checkBox }
        }

        setValue(formRecords)
        setFormData(formRecords)

    }


    return (
    
    <div className="first-stage-container">


        <HeaderTitle title="Find Service Center" />

        <FieldLabelText title="I am looking for" />

        <div className="flex">
            <CheckBox ml={'ml-0'} title="Health Facilities" onClick={() => { selectCheckBox('health_fac', 'Health Facilities') }} isChecked={formData.checkBox.health_fac} />

            <CheckBox ml={'ml-50'} title="Pharmacies" onClick={() => { selectCheckBox('pharmacies', 'Pharmacies') }} isChecked={formData.checkBox.pharmacies} />
        </div>

        <div className="input-field-search">
        <InputField value={formData.around_location} setInputField={(val: string) => setInputField(val, 'around_location')} title="Around (Location) " placeholder="current Location"  type="text" name="around_location"/>
        <InputField value={formData.within_distance} setInputField={(val: string) => setInputField(val, 'within_distance')} title="Within the distance " placeholder="e.g 5km"  type="text" name="within_distance"/>
        </div>



    </div>
    )
}

export default FistStage;