

// export const conditionalRenderService = (property: string, data: string[]) => {

//     if (property === 'Condoms') {
//         return data.includes("Condoms");
//     }

//     if (property === 'Lubricants') {
//         return data.includes("Lubricants");
//     }

//     if (property === 'Clean_Needles') {
//         return data.includes("Clean Needles");
//     }

//     if (property === 'STI_Treatment_Medications') {
//         return data.includes("STI Treatment Medications");
//     }

//     if (property === 'HIV_Self_Test_Kits') {
//         return data.includes("HIV Self-Test Kits");
//     }

//     if (property === 'Emergency_Contraceptives') {
//         return data.includes("Emergency Contraceptives");
//     }

//     if (property === 'Pain_Relief_Medication') {
//         return data.includes("Pain Relief Medication");
//     }

//     if (property === 'Other') {
//         return data.includes("Other");
//     }

// }

 
export const conditionalRenderService = (property: string, data: string[] | null) => {

    if (!data) {
        return false; // Handle the case where data is null
    }

    if (property === 'Condoms') {
        return data.includes("Condoms");
    }

    if (property === 'Lubricants') {
        return data.includes("Lubricants");
    }

    if (property === 'Clean_Needles') {
        return data.includes("Clean Needles");
    }

    if (property === 'STI_Treatment_Medications') {
        return data.includes("STI Treatment Medications");
    }

    if (property === 'HIV_Self_Test_Kits') {
        return data.includes("HIV Self-Test Kits");
    }

    if (property === 'Emergency_Contraceptives') {
        return data.includes("Emergency Contraceptives");
    }

    if (property === 'Pain_Relief_Medication') {
        return data.includes("Pain Relief Medication");
    }

    if (property === 'Other') {
        return data.includes("Other");
    }

    return false; // Return false if property doesn't match any condition
};
