import React, { useState } from "react";
import "../../assests/styles/selectmediacenter.css";
import ArrowIcon from "../../assests/icons/angledown.svg";
import HospitalIcon from "../../assests/icons/Hospital.svg";
import useStates from "hooks/useStates";
interface SelectDropdownProps {
  value: string | null;
  onChange: (value: string) => void;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({onChange, value}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string | null>(value?value:"");
  
const {getFacilities_data} = useStates()


  const options = getFacilities_data?.map((center:any) => center.name);
   
  

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="select-dropdown">
      <div className="select-input  " onClick={()=>toggleDropdown()}>
        <span>{value?value:selectedOption || "Select an option"}</span>
        <img src={ArrowIcon} alt="Arrow Down" className="arrow-icon" />
        <img src={HospitalIcon} alt="Bag Icon" className="hospital-icon" />
      </div>
      {isOpen && (
        <ul className="dropdown-list overflow-y-auto h-40s border-grey  pl-4 rounded-sm">
          { options ?options.map((option:any) => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          )):( <div className="flex items-center text-[15px] text-primary_color ">
          Loading
        </div>)}
        </ul>
      )}
    </div>
  );
};

export default SelectDropdown;
