import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./types";




const initialState :AuthState={
    register_message: null,
    login_message: null,
    registration_error: null,
    login_error: null,
    confirmation_error: null,
    confirmation_message: null,
    token: null,
    userInfo: null,
    rememberMe: false,
    forgot_error: null,
    forgot_message: null,
    reset_error: null,
    reset_message: null,
    isAuthenticated: false,
    user_loading: true,
    error: null,
    isAllowed: true,
    email_verified_at:false
    
 
}


const authSlice= createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setRegisterMessage: (state:AuthState, action: PayloadAction<string>) => {
            state.register_message = action.payload;
        },
        setLoginMessage: (state:AuthState, action: PayloadAction<string>) => {
            state.login_message = action.payload;
        },
        setRegistrationError: (state:AuthState, action: PayloadAction<string>) => {
            state.registration_error = action.payload;
        },
        setLoginError: (state:AuthState, action: PayloadAction<string>) => {
            state.login_error = action.payload;
        },
        setConfirmationError: (state:AuthState, action: PayloadAction<string>) => {
            state.confirmation_error = action.payload;
        },
        setConfirmationMessage: (state:AuthState, action: PayloadAction<string>) => {
            state.confirmation_message = action.payload;
        },
        setToken: (state:AuthState, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    
        setUserInfo: (state:AuthState, {payload}: PayloadAction<object>) => {
            if (payload !== null) {
                state.userInfo = payload;
              }
        },

        setIsAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
          },
          setIsAllowed: (state: AuthState, action: PayloadAction<boolean>) => {
            state.isAllowed= action.payload;
          },
    }
})


export const { setUserInfo,setIsAuthenticated, setToken, setConfirmationMessage,setConfirmationError, setLoginError, setRegistrationError, setLoginMessage, setRegisterMessage,setIsAllowed} = authSlice.actions

export default authSlice.reducer
