import React from 'react';
import Arrowleft from "../../assests/icons/Arrowleft.svg";




interface ButtonProps {

    goBack: Function,
    title: string,

}

const BackNavigation: React.FC<ButtonProps> = ({ goBack, title }) => {


    return (<div className='mt-8 w-[80%] flex justify-start'>

        <div className="flex nav-back cursor-pointer" onClick={() => goBack()}>
            <img src={Arrowleft} alt="arrow-left" /> <div className='ml-3 ' >{title}</div>
        </div>

    </div>);


}

export default BackNavigation;