import React from "react";

import { LoginType } from "./types";
import { errorType } from "./types";

const initialLoginState: LoginType = {

    email: "",
    password: "",
    is_form_submitted:false


};



export default function useLoginForm() {
    // const dispatch = useDispatch();
    const [loginValues, setLoginValues] =
        React.useState<LoginType>(initialLoginState);


    const [errors, setErrors] = React.useState<errorType>({});


    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setErrors({ ...errors, ...{ [name]: "", username: "", invalid_code: '' } });

        setLoginValues((prevValues) => {
            // Create a copy of the previous state
            const updatedValues = { ...prevValues, [name]: value };


            return updatedValues;
        });
    };




    const setLoginErrors = (key: string, error: string) => {
        setErrors({ ...errors, ...{ [key]: error } });
    }


    const setFormSubmitStatus = (is_form_submitted: boolean) => {
        setLoginValues({ ...loginValues, ...{ is_form_submitted } });
    }


    const validateLogin = () => {
        const temp: errorType = {};
        var status = true;



        if (!loginValues.email) {
            temp.email = "Email field is required";
            status = false;
        }

        else if (!/^\S+@\S+\.\S+$/.test(loginValues.email)) {
            temp.email = "Invalid email format.";
            status = false;
          }

        if (!loginValues.password) {
            temp.password = "Password field is required";
            status = false;
        }



        setErrors({ ...temp });

        return status;



    };


    return {
        handleChange,
        validateLogin,
        errors,
        setErrors,
        loginValues,
        setLoginErrors,
        setFormSubmitStatus,
    };
}
