import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from 'framer-motion';
import RequestLabservice1 from "../../../../components/RequestLabService/RequestLabservice1";
import RequestLabservice2 from "../../../../components/RequestLabService/RequestLabservice2";
import RequestLabservice3 from "../../../../components/RequestLabService/RequestLabservice3";
import RequestLabservice4 from "../../../../components/RequestLabService/RequestLabservice4";
import RequestLabservice5 from "../../../../components/RequestLabService/RequestLabservice5";
import RequestLabservice6 from "../../../../components/RequestLabService/RequestLabservice6";
import RequestLabservice7 from "../../../../components/RequestLabService/RequestLabservice7";
import RequestLabservice8 from "../../../../components/RequestLabService/RequestLabservice8";
import OnSuccessPage from "components/RequestLabService/OnSuccessPage";
import NotEligible from "../../../../components/RequestLabService/NotEligible";

const steps = [
  'Step1', 'Step2', 'Step3', 'Step4', 'Step5', 'Step6', 'Step7', 'Step8', 'Step9', 'NotEligible'
];

const variants = {
  enter: {
    opacity: 0,
    x: 100,
  },
  center: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -100,
  },
};

const RequestLabServices = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNotEligible = () => {
    const notEligibleStepIndex = steps.indexOf('NotEligible');
    if (notEligibleStepIndex !== -1) {
      setCurrentStep(notEligibleStepIndex);
    }
  };

  return (
    <div className="request-lab-service-container">
      <AnimatePresence mode='wait' >
        <motion.div
          key={currentStep}
          initial="enter"
          animate="center"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.5 }}
        >
          {steps[currentStep] === 'Step1' && <RequestLabservice1 onNext={nextStep} />}
          {steps[currentStep] === 'Step2' && <RequestLabservice2 onNext={nextStep} onPrevious={prevStep} />}
          {steps[currentStep] === 'Step3' && <RequestLabservice3 onNext={nextStep} onPrevious={prevStep} onNotEligible={handleNotEligible} />}
          {steps[currentStep] === 'Step4' && <RequestLabservice4 onNext={nextStep} onPrevious={prevStep} />}
          {steps[currentStep] === 'Step5' && <RequestLabservice5 onNext={nextStep} onPrevious={prevStep} />}
          {steps[currentStep] === 'Step6' && <RequestLabservice6 onNext={nextStep} onPrevious={prevStep} />}
          {steps[currentStep] === 'Step7' && <RequestLabservice7 onNext={nextStep} onPrevious={prevStep} />}
          {steps[currentStep] === 'Step8' && <RequestLabservice8 onNext={() => navigate('/success')} onPrevious={prevStep} onSubmit={() => console.log('Data submitted')} />}
          {steps[currentStep] === 'NotEligible' && <NotEligible onPrevious={prevStep} />}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default RequestLabServices;
