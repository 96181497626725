import React, { useState, useRef, ChangeEvent, KeyboardEvent, ClipboardEvent, FocusEvent, } from 'react';


interface EnterCodeProps {
  callback: (code: string) => void;

  isLoading: boolean;
}

const EnterCode: React.FC<EnterCodeProps> = ({ callback, isLoading }) => {
  const [code, setCode] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);


    callback(newCode.join(''));

    // Move focus to next input field if value is not empty
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const newCode = [...code];

    // Loop through the pasted text and update the code array
    for (let i = 0; i < paste.length && i < 6; i++) {
      newCode[i] = paste[i];
    }

    setCode(newCode);
    callback(newCode.join(''));
    inputRefs.current[paste.length - 1]?.focus();
  };





  return (
    <div className="flex gap-2 relative">
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <input
          className="text-2xl bg-stroke w-10 flex p-2 text-center"
          key={index}
          type="text"
          maxLength={1}
          onChange={(e) => handleInput(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          autoFocus={index === 0}
          onFocus={handleFocus}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          disabled={isLoading}
          value={code[index]}
        />
      ))}
      {/* {code.length ? <ClearButton /> : null} */}
    </div>
  );
};

export default EnterCode;
