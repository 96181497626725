/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import EnterCode from "./EnterCode";
import ValidationTitle from "components/shared/validation";
import { useSelector } from "react-redux";
import type { RootState } from "../../Store";
type EmailCodeVarificationProps = {
  onNext: (code: string) => void;
  registerValues: any;
  errors: any;
  resendCode: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  is_code_reset: boolean;
};

const EmailCodeVarification: React.FC<EmailCodeVarificationProps> = ({
  onNext,
  registerValues,
  errors,
  resendCode,
  is_code_reset,
}) => {
  const [email_code, setEmailCode] = useState("");
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const handleCodeSubmit = (code: string) => {
    setEmailCode(code);
  };

  const verifyCode = () => {
    onNext(email_code);
  };

  return (
    <div className="h-screen flex items-center justify-center w-screen px-4">
      <div className="flex flex-col gap-6 justify-between w-full max-w-md p-4 md:flex-row md:max-w-4xl">
        <div className="flex flex-col justify-center md:w-1/2">
          <h2 className="w-full text-2xl font-semibold md:text-3xl">
            Verify Email Address
          </h2>
          <p className="text-md font-medium text-gray-500 md:w-[300px]">
            Thank you for registering! Check your email {userInfo?.email} for
            the verification code to complete your registration process.
          </p>
        </div>
        <div className="flex flex-col gap-8 justify-center md:w-1/2 md:mt-10">
          <section className="flex justify-center items-center w-full">
            <div className="w-full">
              <EnterCode
                isLoading={registerValues.is_form_submitted}
                callback={handleCodeSubmit}
              />
            </div>
          </section>
          <button
            disabled={registerValues.is_form_submitted}
            className="bg-primary_color py-3 text-lg text-white font-medium w-full"
            onClick={verifyCode}
          >
            {registerValues.is_form_submitted ? "Verifying...." : "Verify"}
          </button>
          {errors.invalid_code && (
            <ValidationTitle title={errors.invalid_code} />
          )}
          <div className="flex flex-col gap-4 mt-6 text-center md:flex-row md:justify-between">
            <p>
              Need Help?{" "}
              <a
                href="#"
                className="text-primary_color"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                Chat with Us
              </a>
            </p>
            <p>
              Didn’t see code?{" "}
              <a
                href="#"
                onClick={(event) => resendCode(event)}
                className="text-primary_color"
                style={{ cursor: "pointer" }}
                aria-disabled={is_code_reset}
              >
                {is_code_reset ? "Sending" : "Resend"}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCodeVarification;
