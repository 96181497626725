import React, { useState, useEffect } from 'react';
import '../../assests/styles/request-lab-service.css';
import { useDispatch } from "react-redux";
import { setLabTestDoneAdditionalInfo } from 'Features/app/RequestLabService/requestLabServices.slice';

interface TextInputProps {
  value: string;
  onChange: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({ value, onChange }) => {
  const dispatch = useDispatch<any>();
  const [message, setMessage] = useState(value);

  useEffect(() => {
    dispatch(setLabTestDoneAdditionalInfo(message));
  }, [message, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setMessage(newValue);
    onChange(newValue);
  };

  return (
    <div className="message-field">
      <textarea
        value={message}
        onChange={handleChange}
        className="message-input"
        placeholder="Enter your message here..."
        rows={5}
      />
    </div>
  );
};

export default TextInput;
