import ChatBox from "components/Chat/ChatBox";
import { IoSearch } from "react-icons/io5";
import ChatMainBox from "components/Chat/ChatMainBox";
export default function ChatIndex() {
  return (
    <div className="md:w-[800px] mx-0">
      <div className=" flex justify-between  ">
        <div className="w-[24rem]">
          <div className="flex flex-col items-start justify-center md:p-2">
            <h3 className="'topographyText font-[500] flex gap-2 text-[45px]">
              Messages
            </h3>
            <div className="pt-2 relative  text-gray-600 w-full">
              <input
                className="border-2 border-[#D0D2E0] bg-white w-full h-10 px-4 pr-4 md:px-8 md:pr-16 rounded-[50px] text-sm focus:outline-none"
                type="search"
                name="search"
                placeholder="Search Direct Message"
              />
              <button className="absolute right-0 md:left-0 top-0 mt-5 mr-4 md:mr-10 text-[#D0D2E0] hover:text-gray-500 focus:outline-none">
                <IoSearch />
              </button>
            </div>
          </div>

          <div className="mt-4">
            <ChatBox />
          </div>
        </div>
       <div className="hidden md:flex">
<ChatMainBox/>
       </div>
      </div>
    </div>
  );
}
