import React from 'react';



interface TitleProp {


    title: string,


}

const ValidationTitle: React.FC<TitleProp> = ({ title }) => {


    return (

        <div className='mb-1 mt-2'>
            <label className='text-customRed'>{title}</label>
        </div>

    );


}

export default ValidationTitle;