import React, { useEffect, useState } from "react";
type AdditionalInformationTypes = {
  setData: (value: string) => void
  value?: string
}
const AdditionalInformation: React.FC<AdditionalInformationTypes> = ({ setData, value = '' }) => {
  const [additionalInfo, setAdditionalInfo] = useState(value);
  //console.log(additionalInfo)

  const setDataFromElement = (val: string) => {
    setAdditionalInfo(val);
    setData(val);

  }

  useEffect(() => {
    setAdditionalInfo(value)
  }, [value])

  //setData(additionalInfo)
  return (
    <div>
      <label
        htmlFor="Is there anything else you would like to share about your health product needs or preferences? (Text Field)"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Is there anything else you would like to share about your health product needs or preferences? (Text Field)
      </label>

      <textarea
        id="Is there anything else you would like to share about your health product needs or preferences? (Text Field)"
        rows={4}
        value={additionalInfo}
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Write your thoughts here..."
        onChange={(e) => setDataFromElement(e.target.value)}
      ></textarea>
    </div>
  );
}
export default AdditionalInformation