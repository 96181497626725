import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import NetLifePatientPage from "./views/patients/pages";
import { Provider } from "react-redux";

import { store } from "./Store";
import "./App.css";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
     
        <NetLifePatientPage />
   
    </Provider>
  </BrowserRouter>
);
