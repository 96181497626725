import React from 'react';



interface CheckBoxProps {

    onClick: React.MouseEventHandler<HTMLInputElement>,
    title: string,
    isChecked: string,
    ml: string

}

const CheckBox: React.FC<CheckBoxProps> = ({ onClick, title, isChecked, ml }) => {

    return (<div className={`flex ${ml} items-center mt-3 mb-5`}>
        <input type="checkbox" id={title.replaceAll(' ', '_')} className="hidden" onClick={onClick} />
        <label htmlFor={title.replaceAll(' ', '_')} className="flex items-center cursor-pointer">
            <div className="w-6 h-6 border-2 border-gray-300 rounded-full flex items-center justify-center">
                <svg className={`w-4 h-4 ${!isChecked ? 'text-white' : ''} pointer-events-none`}>
                    <circle cx="50%" cy="50%" r="45%" fill="currentColor" />
                </svg>
            </div>
            <span className="text-gray-900 ml-2">{title}</span>
        </label>

    </div>);


}

export default CheckBox;