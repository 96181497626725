import React from 'react';



interface SuccessValidationProp {


    title: string,


}

const SuccessValidation: React.FC<SuccessValidationProp> = ({ title }) => {


    return (

        <div className='mb-14 mt-2'>
            <label className='text-customGreen'>{title}</label>
        </div>

    );


}

export default SuccessValidation;