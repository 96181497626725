import FirstDocScreen from "./firstDocScreen";
import SecondDocScreen from "./secondDocScreen";

const SeeDoctorComp = () => {
    return (<div>


        <FirstDocScreen />
        <SecondDocScreen />


    </div>)
}

export default SeeDoctorComp;