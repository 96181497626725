import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RequestService from "services/requestService";
import { useLocation } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

// eslint-disable-next-line no-empty-pattern
const ChangePasswordPage = ({}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitted, setFormSubmitStatus] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState<any>();
  const [showPasswordCheck, setShowPasswordcheck] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

  const { id } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      setValidEmail(email);
    }
  }, [email]);

  const handleSubmit = async () => {
    if (!isValid()) {
      setError("Please enter a valid password");
      return;
    }
    if (newPassword.trim() === "" || confirmPassword.trim() === "") {
      setError("Both fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setError("");

    setFormSubmitStatus(true);

    await RequestService.postMethod("reset-password", {
      password: newPassword,
      password_confirmation: confirmPassword,
      email: validEmail,
      token: id,
    })
      .then((response) => {
        setFormSubmitStatus(false);

        const responseData = response.data.responseData;

        setSuccess(responseData);

        navigate("/SignIn");
      })
      .catch((error) => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setError(errorData?.responseData);
        }
        if (errorData?.errors) {
          const errors = errorData.errors;
          setError(errors.email[0]);
        }
      });
  };

  const onClose = () => {};

  const validatePassword = (password: string) => {
    const errors = [
      { message: "At least 8 characters long", isValid: true },
      { message: "Password should not exceed 16 characters", isValid: true },
      { message: "At least one lowercase letter", isValid: true },
      { message: "At least one uppercase letter", isValid: true },
      { message: "At least one number", isValid: true },
    ];

    if (password.length < 8) {
      const minLengthError = errors.find(
        (error) => error.message === "At least 8 characters long"
      );
      if (minLengthError) {
        minLengthError.isValid = false;
      }
    }

    if (password.length > 16) {
      const maxLengthError = errors.find(
        (error) => error.message === "Password should not exceed 16 characters"
      );
      if (maxLengthError) {
        maxLengthError.isValid = false;
      }
    }

    if (!/[a-z]/.test(password)) {
      const lowerCaseError = errors.find(
        (error) => error.message === "At least one lowercase letter"
      );
      if (lowerCaseError) {
        lowerCaseError.isValid = false;
      }
    }

    if (!/[A-Z]/.test(password)) {
      const upperCaseError = errors.find(
        (error) => error.message === "At least one uppercase letter"
      );
      if (upperCaseError) {
        upperCaseError.isValid = false;
      }
    }

    if (!/[0-9]/.test(password)) {
      const numberError = errors.find(
        (error) => error.message === "At least one number"
      );
      if (numberError) {
        numberError.isValid = false;
      }
    }

    return errors;
  };

  const isValid = () => {
    return passwordValidation?.every((error: any) => error.isValid);
  };

  const getColorClass = (isValid: boolean) => {
    return isValid ? "text-customGreen" : "text-customRed";
  };

  const displayPasswordCheck = useCallback(() => {
    let passwordinput = document.getElementById("passwordInput");

    if (document.activeElement === passwordinput) {
      setShowPasswordcheck(true);
    }
    if (document.activeElement !== passwordinput) {
      setShowPasswordcheck(false);
    }
  }, [setShowPasswordcheck]);

  useEffect(() => {
    displayPasswordCheck();
    const validation = validatePassword(newPassword);
    setPasswordValidation(validation);
  }, [displayPasswordCheck, newPassword, setShowPasswordcheck, showPasswordCheck]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        zIndex: 9999990,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "0.375rem",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: "90%",
          maxWidth: "30rem",
          position: "relative",
        }}
      >
        <button
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            padding: "0.5rem 1rem",
            borderRadius: "0.375rem",
            marginBottom: "1rem",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: "1.5rem", width: "1.5rem", marginRight: "0.5rem" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
          Close
        </button>
        <h2
          style={{ fontSize: "1.5rem", marginBottom: "1rem", color: "#1f2937" }}
        >
          Change Your Password
        </h2>
        <div style={{ marginBottom: "1rem" }}>
          <label
            style={{
              display: "block",
              color: "#4b5563",
              fontSize: "0.875rem",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            New Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter new password"
            style={{
              border: "1px solid #d1d5db",
              padding: "0.5rem",
              borderRadius: "0.375rem",
              width: "100%",
            }}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            id="passwordInput"
          />
          {showPasswordCheck && (
            <div className="text-left">
              <p className="font-semibold mb-2">Your password must contain:</p>
              <ul className="list-none space-y-2">
                {passwordValidation?.map((error: any, index: any) => (
                  <li
                    key={index}
                    className={`flex gap-2 justify-start items-center ${getColorClass(
                      error.isValid
                    )}`}
                  >
                    {error.isValid ? (
                      <span>
                        <FaCheck className="text-green-600" />
                      </span>
                    ) : (
                      <span>
                        <FaTimes className="text-green-600 " />
                      </span>
                    )}
                    <p className="!m-0">{error.message}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <label
            style={{
              display: "block",
              color: "#4b5563",
              fontSize: "0.875rem",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            Confirm Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Confirm new password"
            style={{
              border: "1px solid #d1d5db",
              padding: "0.5rem",
              borderRadius: "0.375rem",
              width: "100%",
            }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
          <label
            style={{
              marginLeft: "0.5rem",
              color: "#4b5563",
              fontSize: "0.875rem",
              fontWeight: "bold",
            }}
          >
            Show Password
          </label>
        </div>
        {error && (
          <p
            style={{
              color: "#f87171",
              fontSize: "0.875rem",
              marginTop: "0.5rem",
            }}
          >
            {error}
          </p>
        )}
        {success && (
          <p
            style={{
              color: "#10b981",
              fontSize: "0.875rem",
              marginTop: "0.5rem",
            }}
          >
            {success}
          </p>
        )}
        <button
          style={{
            backgroundColor: "#360284",
            color: "white",
            fontWeight: "bold",
            padding: "0.5rem 1rem",
            borderRadius: "0.375rem",
            width: "100%",
            cursor: "pointer",
            transition: "background-color 0.3s",
          }}
          onClick={handleSubmit}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#059a9a")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#08b4bd")
          }
          disabled={isSubmitted}
        >
          {isSubmitted ? "Sending....." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
