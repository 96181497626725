import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../Store';
import ValidationTitle from 'components/shared/validation';
import RequestService from 'services/requestService';
import SuccessValidation from 'components/shared/success';
import EnterCode from './EnterCode';
import PhoneVerificationPopup from 'components/shared/PhoneVerificationPopup';





const containerStyle: React.CSSProperties = {
  width: '90%',
  maxWidth: '425px',
  height: 'auto',
  padding: '1rem',
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffff',
  margin: '1rem auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headingStyle: React.CSSProperties = {
  fontSize: '1.25rem',
  fontWeight: '600',
  textAlign: 'center',
  marginBottom: '1rem',
  fontFamily: 'poppins',
  color: '#000000',
};

const textStyle: React.CSSProperties = {
  fontSize: '1rem',
  marginBottom: '1rem',
  textAlign: 'center',
};

const inputStyle: React.CSSProperties = {
  border: '1px solid #d1d5db',
  padding: '0.5rem',
  borderRadius: '4px',
  width: '10%',
  textAlign: 'center',
};


const responsiveTextStyle: React.CSSProperties = {
  ...textStyle,
  fontSize: '0.875rem',
  fontFamily: 'poppins',
  fontWeight: '400',
  color: '#000000',
};


const buttonStyle: React.CSSProperties = {
  color: '#8A3FFC',
  cursor: 'pointer',
  fontWeight: '500',
  fontFamily: 'poppins',
  margin: '0.5rem',
  textAlign: 'center',
  display: 'inline-block',
};




const PhoneVerification: React.FC = () => {

  const userInfoData: any = useSelector((state: RootState) => state.auth.userInfo);

  const [isEditingNumber, setIsEditingNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userInfoData?.phone_number);
  const inputRef = useRef<HTMLInputElement>(phoneNumber);
  // const [editedPhoneNumber, setEditedPhoneNumber] = useState(phoneNumber);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');


  const [isPhoneValidated, setIsPhoneValidated] = useState(true);
  const [success, setSuccess] = useState('');
  const [isSubmitted, setFormSubmitStatus] = useState(false);
  // const [timer, setTimer] = useState<number | null>(null);
  const [error, setError] = useState('');
  const [showPopup] = useState(false); // State to control the popup


  const navigate = useNavigate();

  const handleSaveNumber = async () => {

    if (inputRef.current.value.length!== 12) {
      alert('Phone number cannot be empty or invalid');
      return;
    }
    if (inputRef.current.value && inputRef.current.value.length ===12) {

      setPhoneNumber(inputRef.current.value);
      setIsEditingNumber(false);
      
    } else {
      alert('Phone number cannot be empty or invalid');
    }
  };

  const handleVerifyNow = async () => {

    if (phoneNumber.length !== 12) {

      setIsPhoneValidated(false);
      return;
    }

    setFormSubmitStatus(true);
    setError('');

    setIsPhoneValidated(true);

    await RequestService.postMethod('send-phone-code', {
      phone_number: phoneNumber,
      email: userInfoData?.email
    })
      .then(response => {

        setFormSubmitStatus(false);
        const responseData = response.data.responseData;
        setSuccess(responseData);
        setShowVerification(true);
       // setShowPopup(true); // Show popup when phone number is verified
      //  navigate('/userform');

      })
      .catch(error => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setError(errorData?.responseData);
        }
        if (errorData?.errors) {
          const errors = errorData.errors;
          setError(errors.phone_number[0]);
        }
      })


  };

 

  const handleCodeSubmit = async (code: string) => {
    setVerificationCode(code);

  };




  const VerifyCode = async () => {
    setFormSubmitStatus(true);
    setError('');

    setIsPhoneValidated(true);

    await RequestService.postMethod('verify-phone', {
      phone_number: phoneNumber,
      verification_code: Number(verificationCode)
    })
      .then(response => {

        setFormSubmitStatus(false);
        const responseData = response.data.responseData;
        setSuccess(responseData);
        //setShowVerification(true);
        navigate('/userform');
        // setShowVerification(true);

      })
      .catch(error => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setError(errorData?.responseData);
        }
        if (errorData?.errors) {
          const errors = errorData.errors;
          setError(errors.verification_code[0]);
        }
      })
  }


  



  const FirstVerifyInterface = () => {

    return (<>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '1rem' }}>
        <span style={{ fontWeight: '500' }}>
          Phone Number: <span style={{ fontWeight: '700' }}>{phoneNumber}</span>
        </span>
        <span onClick={() => setIsEditingNumber(true)} style={buttonStyle}>Change</span>
      </div>
      <p style={responsiveTextStyle}>
        Please verify your phone number. We will send a code to your phone to verify it.
      </p>
      <button onClick={handleVerifyNow} style={{ ...buttonStyle, backgroundColor: '#8A3FFC', color: '#fff', padding: '0.5rem 1rem', borderRadius: '4px', width:'100%', maxWidth:'50%' }}>
        {isSubmitted ? 'Sending...' : 'Verify Now'}
      </button>
      {/* <button onClick={handleLater} style={{ ...buttonStyle, color: '#8A3FFC', padding: '0.5rem 1rem', borderRadius: '4px', border: '1px solid #8A3FFC', backgroundColor: '#fff' }}>
        Verify Later
      </button> */}

      {!isPhoneValidated && <ValidationTitle title='The phone number is invalid' />}

    </>)
  }


  const PhoneEditInterface = () => {

    return (
      <>
  <p style={responsiveTextStyle}>
    Edit your phone number and save it.
  </p>
  <input
    type="text"
    ref={inputRef}
    defaultValue={phoneNumber}
    maxLength={12}
    onChange={(e) => {
      // Ensure the value contains only numbers
      e.target.value = e.target.value.replace(/\D/g, '');
    }}
    style={{ ...inputStyle, width: '100%' }}
  />
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: '1rem',
    }}
  >
    <button
      onClick={handleSaveNumber}
      style={{
        ...buttonStyle,
        backgroundColor: '#8A3FFC',
        color: '#fff',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
      }}
    >
      Save Number
    </button>
    <button
      onClick={() => setIsEditingNumber(false)}
      style={{
        ...buttonStyle,
        color: '#8A3FFC',
        padding: '0.5rem 1rem',
        borderRadius: '4px',
        border: '1px solid #8A3FFC',
        backgroundColor: '#fff',
      }}
    >
      Cancel
    </button>
  </div>
</>

    )
  }



  

  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f3f4f6' }}>
      <div style={containerStyle}>
        <h2 style={headingStyle}>Secure Your Account</h2>
        <p style={responsiveTextStyle}>
          You're almost done! You've successfully verified your email address. Now, please verify your phone number to complete the process .
        </p>
        {!isEditingNumber && !showVerification ? <FirstVerifyInterface /> : isEditingNumber ? <PhoneEditInterface /> :
          <div><EnterCode isLoading={isSubmitted} callback={handleCodeSubmit} />

            <div className='flex text-center justify-center w-full'>
              <button onClick={VerifyCode}
              disabled={isSubmitted}
              style={{ ...buttonStyle, backgroundColor: '#8A3FFC', color: '#fff', padding: '0.5rem 1rem', borderRadius: '4px' }}>
                {isSubmitted ? 'Sending...' : 'Verify Now'}
              </button>
            </div>
          </div>}

        {error && <ValidationTitle title={error} />}
        {success && <SuccessValidation title={success} />}
      </div>
      {showPopup && <PhoneVerificationPopup />}

    </div>
  );
};



export default PhoneVerification;
