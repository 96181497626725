import { configureStore } from "@reduxjs/toolkit";
import authSliceReducer from './Features/auth/auth.slice';
import HivCareSlice from "Features/app/RequestLabService/HivCare.slice";
import RequestLabServiceReducer from './Features/app/RequestLabService/requestLabServices.slice'




export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    requestLabService:RequestLabServiceReducer,
    hivCare: HivCareSlice
  },
});
//to be deleted
store.subscribe(()=>{
  console.log(store.getState())
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
