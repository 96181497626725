import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menuBars from '../../assets/headerImages/XMLID_101_.png';
import logo from '../../assets/headerImages/Layer 2.png';
import search from '../../assets/headerImages/search.png';
import ellipse from '../../assets/headerImages/Ellipse 71.png';
import house from '../../assets/headerImages/home.png';
import notification from '../../assets/headerImages/Group 1000000904.png';
import doctor from '../../assets/headerImages/Group 1000000948.png';
import chat from '../../assets/headerImages/Chatting.png';
import searchBottom from '../../assets/headerImages/Layer_1.png';
import MobileSideNavBar from './MobileSideNavBar'; // Correct path assumed

export interface MobileHeaderProps {
  onOpenSidebar: () => void;
  onLogout: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ onOpenSidebar, onLogout }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleMenuClick = () => {
    setIsSidebarVisible(true);
    onOpenSidebar(); // Optionally trigger any necessary action when sidebar opens
  };

  const handleCloseSidebar = () => {
    setIsSidebarVisible(false);
  };

  return (
    <div className="block lg:hidden">
      {/* Top Header */}
      <header style={{ backgroundColor: 'white' }} className="fixed top-0 left-0 right-0 shadow-md flex justify-between items-center p-4 z-20">
        <div className="flex items-center space-x-2">
          <img
            src={menuBars}
            alt="Menu"
            width="25"
            height="20"
            className="cursor-pointer"
            onClick={handleMenuClick}
          />
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              width="50%"
              height="26.91"
              className="cursor-pointer"
            />
          </Link>
        </div>
        <div className="flex items-center">
          <Link to="/search">
            <img
              src={search}
              alt="Search"
              width="20"
              height="20"
              className="cursor-pointer"
            />
          </Link>
          <img
            src={ellipse}
            alt="Ellipse"
            width="20"
            height="20"
            className="cursor-pointer ml-2"
          />
        </div>
      </header>

      {/* Sidebar */}
      {isSidebarVisible && (
        <MobileSideNavBar onClose={handleCloseSidebar} onLogout={onLogout} />
      )}

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md flex justify-around items-center p-4" style={{ zIndex: '999999', backgroundColor: 'white', height: '12%' }}>
        <Link to="/home">
          <img
            src={house}
            alt="Home"
            width="20"
            height="20"
            className="cursor-pointer"
          />
        </Link>
        <Link to="/notifications">
          <img
            src={notification}
            alt="Notifications"
            width="30"
            height="30"
            className="cursor-pointer"
          />
        </Link>
        <Link to="/doctor">
          <img
            src={doctor}
            alt="Doctor"
            width="32"
            height="32"
            className="cursor-pointer"
          />
        </Link>
        <Link to="/chat">
          <img
            src={chat}
            alt="Chat"
            width="20"
            height="20"
            className="cursor-pointer"
          />
        </Link>
        <Link to="/search">
          <img
            src={searchBottom}
            alt="Search"
            width="20"
            height="20"
            className="cursor-pointer"
          />
        </Link>
      </div>
    </div>
  );
};

export default MobileHeader;
