import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
import { AnimatePresence, motion } from "framer-motion";
import LabServiceQuestions from "components/RequestLabServiceComponents/ScreeningQuestions";
import LabServiceEligiblePatient from "components/RequestLabServiceComponents/LabServiceEligiblePatient";
import NotEligible from "../../../../components/RequestLabService/NotEligible";
import TypeOfLabServices from "components/RequestLabServiceComponents/TypesOfLabServices";
import useRequestLabService from "hooks/useRequestLabService";
import LabServiceCollectSampleFrom from "components/RequestLabServiceComponents/LabServiceCollectSampleFrom";
import GetResultsFrom from "components/RequestLabServiceComponents/GetResultsFrom";
import ServicesPreferences from "components/RequestLabServiceComponents/ServicePreferences";
import ServiceWelcomePage from "components/RequestLabServiceComponents/ServiceWelcomePage";
import HivLabTestData from "./HivLabTestdata.json";
import PrePRequestDetails from "components/PrepRequestDetails";
import YourARTRequestDetails from "components/YourARTRequestDetails";
import MentalHealthServiceNeeds from "components/MentalHealthServiceNeeds";
import DeliveryPreferences from "components/DeliveryPreferences";
import HPProductRequestDetails from "../HPProductRequestDetails/HPProductRequestDetails";
import PEPRequestDetails from "components/PEPRequestDetails";
import PrepSummaryPage from "../SummaryPage/PrepSummaryPage";
import {
  setEditSummary
} from "Features/app/RequestLabService/HivCare.slice";
import { conditionalRenderService } from "services/healthProductService";
import type { RootState } from '../../../../Store';
import { getGenderIdentity } from "services/requestService";

const steps = [
  "Step1",
  "Step2",
  "Step3",
  "Step4",
  "Step5",
  "Step6",
  "Step7",
  "Step8",
  "Step9",
  "NotEligible",
];

type HivLabServiceTypes = {

};

const variants = {
  enter: {
    opacity: 0,
    x: 100,
  },
  center: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -100,
  },
};

const HivLabService: React.FC<HivLabServiceTypes> = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState<any>(0);
  const { lapServiseState } = useRequestLabService();

  const { selectedLabTest } = lapServiseState;
  const prepData = useSelector(
    (state: any) => state.hivCare.Pre_Exposure_Prophylaxis
  );
  const hivTest = useSelector(
    (state: any) => state.requestLabService

  );
  const ArtData = useSelector(
    (state: any) => state.hivCare.Antiretroviral_Therapy
  );
  const HealthProductsData = useSelector(
    (state: any) => state.hivCare.Health_Products
  );

  const pepData = useSelector(
    (state: any) => state.hivCare.Post_Exposure_Prophylaxis
  );
  const MentalHeathData = useSelector(
    (state: any) => state.hivCare.Mental_Health_Services
  );

  const edit_from_summary = useSelector(
    (state: any) => state.hivCare.edit_from_summary
  );
  const nextStep = () => {
    // console.log("Next step triggered");
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNotEligible = () => {
    
    setCurrentStep("notEligible");
    return;
  };

  const userInfoData: any = useSelector((state: RootState) => state.auth.userInfo);
  //console.log(currentStep)
  useEffect(() => {

    if (edit_from_summary) {
      dispatch(setEditSummary(false));
      setCurrentStep(currentStep - 1);

    }
    if (currentStep === 0) {
      dispatch(getFacilities() as any);
    }

    window.scrollTo(0, 0);
  }, [edit_from_summary, currentStep, dispatch]);
  const renderStep = () => {
    if (currentStep === 0) {
      return (
        <ServiceWelcomePage
          onNext={nextStep}
          onPrevious={() => ""}
          title={HivLabTestData.WelcomePage.title || null}
          locationSubTitle={HivLabTestData.WelcomePage.LocationSubTitle || null}
        />
      );
    }

    if (currentStep === 1) {
      return (
        <TypeOfLabServices
          onNext={nextStep}
          onPrevious={prevStep}
          Labservices={HivLabTestData.labServices.map((service) => ({
            id: service.id,
            text: service.name,
          }))}
          title={HivLabTestData.ServiceConfig.title || null}
          subTitle={HivLabTestData.ServiceConfig.Subtitle || null}
        />
      );
    }

    if (currentStep === 2) {
      return (
        selectedLabTest && (
          <LabServiceQuestions
            onNext={nextStep}
            onPrevious={prevStep}
            introduction={
              (HivLabTestData.labServices.find(
                (service) => service.name === selectedLabTest
              )?.service_introduction as any) || []
            }
            question={
              (HivLabTestData.labServices.find(
                (service) => service.name === selectedLabTest
              )?.questions as any) || []
            }
            onNotEligible={handleNotEligible as any}
          />
        )
      );
    }
    if (currentStep === "notEligible") {
      return (
        <NotEligible
          onNext={() => ""}
          onPrevious={prevStep}
          tip={
            (HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.NoEligibal_tip as any) || []
          }
          CTA={
            (HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.NotEligibale_CTA as any) || []
          }
          title={
            (HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.NotEligibal_introduction as any) || []
          }
        />
      );
    }
    if (currentStep === 3) {
      return (
        <LabServiceEligiblePatient
          onNext={nextStep}
          onPrevious={prevStep}
          introduction={
            (HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.introductionEligibility as any) || []
          }
          tip={
            (HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.tip as any) || []
          }
          CTA={
            HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.Eligibal_CTA || null
          }
          StepTowards={
            HivLabTestData.labServices.find(
              (service) => service.name === selectedLabTest
            )?.Step_towards || null
          }
        />
      );
    }

    switch (selectedLabTest) {
      case "HIV Testing Services (HTS)":
        if (currentStep === 4) {

          return (
            <LabServiceCollectSampleFrom
              onNext={nextStep}
              onPrevious={prevStep}
              options={
                (HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.CollectSamplesOptions as any) || []
              }
              title={
                HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.title_sample || null
              }
              selectCollectSampleFrom={hivTest.collectSampleFrom}
            />
          );
        }
        if (currentStep === 5) {
          return (
            <GetResultsFrom
              onNext={nextStep}
              onPrevious={prevStep}
              options={
                (HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.GetResultsFrom as any) || []
              }
              title={
                HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.title_sample_results || null
              }
              selectedoptions={hivTest.getResultsFrom}
            />
          );
        }
        if (currentStep === 6) {
          return (
            <ServicesPreferences
              onNext={nextStep}
              onPrevious={prevStep}

              title={
                HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.title_selection || null
              }
              additional_Info={
                HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.additional_info || null
              }
              date_title={
                HivLabTestData.labServices.find(
                  (service) => service.name === selectedLabTest
                )?.data_title || null
              }
            />
          );
        }
        if (currentStep === 7) {

          const PrepData = [
            {
              serviceType: "HIV Testing Services (HTS).",
              questions: [
               
                {
                  title: "1. Personal Details",
                  items: [
                    {
                      label: "First Name",
                      value: userInfoData.first_name
                      ,
                    },
                    { label: "Last Name", value: userInfoData.last_name },
                    { label: "Gender", value: getGenderIdentity(userInfoData.gender) },
                    { label: "Date of Birth", value: userInfoData.date_of_birth },
                    // { label: "District", value: userInfoData.district },
                  ],
                },

                {
                  title: "2.Contact Details",
                  items: [
                    {
                      label: "Phone",
                      value: userInfoData.phone_number
                      ,
                    },
                    { label: "WhatsApp", value: userInfoData.whatsap_number },

                    { label: "Email Address", value: userInfoData.email },
                  ],
                },

                {
                  title: "3. Your HIV Testing Service Details",
                  items: [
                    {
                      label: "Where do you want us to collect your sample from?",
                      value: hivTest.collectSampleFrom,
                    },
                    {
                      label: "How do you want to get your HIV results?",
                      value: hivTest.getResultsFrom,
                    },
                    {
                      label: "Preferred Delivery Date",
                      value: hivTest.LabTestDone.SeletedDateAndTime,
                    },
                    {
                      label: "Preferred Delivery Time",
                      value: hivTest.LabTestDone.SeletedTime,
                    },
                  ],
                },
              ],
            },
          ];


          return (
            <PrepSummaryPage
            onSubmit={() => ""}
            data={PrepData as []}
            service="HIV_S"
          />
          );
        }
        break;


      case "Pre-Exposure Prophylaxis (PrEP)":
        if (currentStep === 4) {
          return <PrePRequestDetails onNext={nextStep} onPrevious={prevStep} />;
        }
        if (currentStep === 5) {
          const PrepData = [
            {
              serviceType: "Pre-Exposure Prophylaxis (PrEP)",
              questions: [
                {
                  title: "1. Personal Details",
                  items: [
                    {
                      label: "First Name",
                      value: userInfoData.first_name
                      ,
                    },
                    { label: "Last Name", value: userInfoData.last_name },
                    { label: "Gender", value: getGenderIdentity(userInfoData.gender) },
                    { label: "Date of Birth", value: userInfoData.date_of_birth },
                    // { label: "District", value: userInfoData.district },
                  ],
                },

                {
                  title: "2.Contact Details",
                  items: [
                    {
                      label: "Phone",
                      value: userInfoData.phone_number
                      ,
                    },
                    { label: "WhatsApp", value: userInfoData.whatsap_number },

                    { label: "Email Address", value: userInfoData.email },
                  ],
                },

                {
                  title: "3. Your PreP Request Details",
                  items: [
                    {
                      label: "Last Test Date",
                      value: prepData.last_test_date,
                    },
                    {
                      label: "Last Test Report File",
                      value: prepData.report_file_uri?.name,
                    },
                    {
                      label: "Preferred Delivery Method",
                      value: prepData.result_delivery_method,
                    },
                    {
                      label: "Preferred Delivery Date",
                      value: prepData.preferred_date,
                    },
                    {
                      label: "Preferred Delivery Time",
                      value: prepData.preferred_time,
                    },
                  ],
                },
              ],
            },
          ];

          return (
            <PrepSummaryPage
              onSubmit={() => ""}
              data={PrepData as []}
              service="PREP_S"
            />
          );
        }
        break;

      case "Post-Exposure Prophylaxis (PEP)":
        if (currentStep === 4) {
          return <PEPRequestDetails onNext={nextStep} onPrevious={prevStep} />;
        }

        if (currentStep === 5) {
          const PepData = [
            {
              serviceType: "Post-Exposure Prophylaxis (PEP)",
              questions: [
                {
                  title: "1. Personal Details",
                  items: [
                    {
                      label: "First Name",
                      value: userInfoData.first_name
                      ,
                    },
                    { label: "Last Name", value: userInfoData.last_name },
                    { label: "Gender", value: getGenderIdentity(userInfoData.gender) },
                    { label: "Date of Birth", value: userInfoData.date_of_birth }
                    // { label: "Sub County", value: userInfoData.sub_county },
                  ],
                },

                {
                  title: "2.Contact Details",
                  items: [
                    {
                      label: "Phone",
                      value: userInfoData.phone_number
                      ,
                    },
                    { label: "WhatsApp", value: userInfoData.whatsap_number },

                    { label: "Email Address", value: userInfoData.email },
                  ],
                },

                {
                  title: "3. Your PEP Request Details",
                  items: [
                    {
                      label: "Last Test Date",
                      value: pepData.last_test_date,
                    },
                    {
                      label: "Last Test Report File",
                      value: pepData.report_file_uri?.name,
                    },
                    {
                      label: "Preferred Delivery Method",
                      value: pepData.result_delivery_method,
                    },
                    {
                      label: "Preferred Delivery Date",
                      value: pepData.preferred_date,
                    },
                    {
                      label: "Preferred Delivery Time",
                      value: pepData.preferred_time,
                    },
                  ],
                },
              ],
            },
          ];

          return <PrepSummaryPage
            onSubmit={() => ""}
            data={PepData as []}
            service="PEP_S"
          />
        }
        break;
      case "Antiretroviral Therapy (ART)":
        if (currentStep === 4) {
          return (
            <YourARTRequestDetails onNext={nextStep} onPrevious={prevStep} />
          );
        }
        if (currentStep === 5) {

          const PrepData = [
            {
              serviceType: "Antiretroviral Therapy (ART)",
              questions: [
                {
                  title: "1. Personal Details",
                  items: [
                    {
                      label: "First Name",
                      value: userInfoData.first_name
                      ,
                    },
                    { label: "Last Name", value: userInfoData.last_name },
                    { label: "Gender", value: getGenderIdentity(userInfoData.gender) },
                    { label: "Date of Birth", value: userInfoData.date_of_birth }
                  ],
                },

                {
                  title: "2.Contact Details",
                  items: [
                    {
                      label: "Phone",
                      value: userInfoData.phone_number
                      ,
                    },
                    { label: "WhatsApp", value: userInfoData.whatsap_number },

                    { label: "Email Address", value: userInfoData.email },
                  ],
                },

                {
                  title: "3.Your ART Request Details",
                  items: [
                    {
                      label: "Preferred Dosage Duration",
                      value: ArtData.prefered_dosage_duration,
                    },
                    {
                      label: "Preferred Delivery Method",
                      value: ArtData.preferred_date,
                    },
                    {
                      label: "Preferred Delivery Date",
                      value: ArtData.preferred_date,
                    },
                    {
                      label: "Preferred Delivery Time",
                      value: ArtData.preferred_time,
                    },
                    
                  ],
                },
              ],
            },
          ];
          return (
            <PrepSummaryPage
              onSubmit={() => ""}
              data={PrepData as []}
              service="ANTIRE_THEREPY_S"
            />
          );
        }
        break;
      case "Mental Health Services (MHS)":
        if (currentStep === 4) {
          return (
            <MentalHealthServiceNeeds onNext={nextStep} onPrevious={prevStep} />
          );

        }
        if (currentStep === 5) {

          const PrepData = [
            {
              serviceType: "Mental Health Services (MHS)",
              questions: [
                {
                  title: "1. Personal Details",
                  items: [
                    {
                      label: "First Name",
                      value: userInfoData.first_name
                      ,
                    },
                    { label: "Last Name", value: userInfoData.last_name },
                    { label: "Gender", value: getGenderIdentity(userInfoData.gender) },
                    { label: "Date of Birth", value: userInfoData.date_of_birth }
                  ],
                },

                {
                  title: "2.Contact Details",
                  items: [
                    {
                      label: "Phone",
                      value: userInfoData.phone_number
                      ,
                    },
                    { label: "WhatsApp", value: userInfoData.whatsap_number },

                    { label: "Email Address", value: userInfoData.email },
                  ],
                },

                {
                  title: "3.Mental Health Service Needs",
                  items: [
                    {
                      label: "What type of mental health services are you seeking",
                      value: MentalHeathData.mental_health_services,
                    },
                    {
                      label: "Preferred mode of service delivery",
                      value: MentalHeathData.result_delivery_method,
                    },
                    {
                      label: "Preferred Delivery Date",
                      value: MentalHeathData.preferred_date,
                    },
                    {
                      label: "Preferred Delivery Time",
                      value: MentalHeathData.preferred_time,
                    },
                    {
                      label: "Additional Information",
                      value: MentalHeathData.additional_information,
                    },
                  ],
                },
              ],
            },
          ];
          return (
            <PrepSummaryPage
              onSubmit={() => ""}
              data={PrepData as []}
              service="MENTAL_HEALTH_S"
            />
          );
        }
        break;
      case "Health Commodities (HC)":
        if (currentStep === 4) {
          return (
            <HPProductRequestDetails onNext={nextStep} onPrevious={prevStep} />
          );
        }
        if (currentStep === 5) {
          return (
            <DeliveryPreferences onNext={nextStep} onPrevious={prevStep} />
          );
        }
        if (currentStep === 6) {
          const PrepData = [
            {
              serviceType: "Health Commodities (HC)",
              questions: [
                {
                  title: "1. Personal Details",
                  items: [
                    {
                      label: "First Name:",
                      value: userInfoData.first_name
                      ,
                    },
                    { label: "Last Name:", value: userInfoData.last_name },
                    { label: "Gender", value: getGenderIdentity(userInfoData.gender) },
                    { label: "Date of Birth", value: userInfoData.date_of_birth }
                    
                  ],
                },

                {
                  title: "2.Contact Details",
                  items: [
                    {
                      label: "Phone:",
                      value: userInfoData.phone_number
                      ,
                    },
                    { label: "WhatsApp:", value: userInfoData.whatsap_number },

                    { label: "Email Address:", value: userInfoData.email },
                  ],
                },

                {
                  title: "3. Product Request Details ",
                  items: [
                    {
                      label: "Products Needed",
                      value: HealthProductsData.products_needed,
                    },
                    conditionalRenderService('Condoms', HealthProductsData.products_needed) && {
                      label: "Quantity Required for Condoms",
                      value: HealthProductsData.quantity_required_for_condoms
                    },

                    conditionalRenderService('Lubricants', HealthProductsData.products_needed) && {
                      label: "Quantity Required for Lubricants",
                      value: HealthProductsData.quantity_required_for_lubricants
                    },

                    conditionalRenderService('Clean_Needles', HealthProductsData.products_needed) && {
                      label: "Quantity Required for clean needles",
                      value: HealthProductsData.quantity_required_for_clean_needles
                    },

                    conditionalRenderService('STI_Treatment_Medications', HealthProductsData.products_needed) && {
                      label: "Quantity Required for STI Treatment Medications",
                      value: HealthProductsData.quantity_required_for_sti_treatment
                    },
                    conditionalRenderService('HIV_Self_Test_Kits', HealthProductsData.products_needed) && {
                      label: "Quantity Required for HIV Self Test Kits",
                      value: HealthProductsData.quantity_required_for_self_test_kits
                    },
                    conditionalRenderService('Emergency_Contraceptives', HealthProductsData.products_needed) && {
                      label: "Quantity Required for Emergency Contraceptives",
                      value: HealthProductsData.quantity_required_for_emergency_concentraptive
                    },
                    conditionalRenderService('Pain_Relief_Medication', HealthProductsData.products_needed) && {
                      label: "Quantity Required for Pain Relief Medication",
                      value: HealthProductsData.quantity_required_for_pain_relief_medication
                    },

                  ].filter(Boolean),
                },
                {
                  title: "4.Delivery Preferences",
                  items: [
                    {
                      label: "Preferred Delivery Method",
                      value: HealthProductsData.result_delivery_method,
                    },
                    {
                      label: "Preferred Delivery Date",
                      value: HealthProductsData.preferred_date,
                    },
                    {
                      label: "Preferred Delivery Time",
                      value: HealthProductsData.preferred_time,
                    },
                    {
                      label: "Additional Information",
                      value: HealthProductsData.additional_information,
                    },
                  ],
                },
              ],
            },
          ];
          return (
            <PrepSummaryPage
              onSubmit={() => ""}
              data={PrepData as []}
              service="HEALTH_PRODUCT_S"
            />
          );
        }
        break;
      default:
        return null;
    }

    return null;
  };

  return (
    <div className="request-lab-service-container">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial="enter"
          animate="center"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.5 }}
        >
          {renderStep()}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default HivLabService;
