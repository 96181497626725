import profile from '../../assests/images/Ellipse 69.svg'
export default function ChatBox() {
  return (
    <div className='p-2 md:border-r-8 md:border-[#8A3FFC]'>
        <div className='flex gap-2  items-center'>
            <div className=''>
                <img src={profile} alt='logo' className='w-[68px] h-[68px] rounded-[50%]'/>
            </div>
            <div>
                <h3 className='topographyText font-[600] flex gap-2 text-[18px] w-[110%]'>Dr mike james .<span className='font-[400] text-[#596780] text-[15px]'>Fri, Aug 7 2024</span></h3>
                <p className='topographyText text-[18px] font-[400] text-[#596780] '>XXX Medical Center</p>
                <p className='topographyText text-[15px] md:text-[18px] text-ellipsis font-[400] text-[#596780]'>Hi kato, how are you doing</p>
            </div>
            <div className='md:flex flex-col mb-8  hidden'>
                <span className='text-[30px]'>...</span>
                <span className='rounded-full bg-primary_color flex justify-center items-center font-[800] text-[#E9E9E9] stroke-current w-[30px] h-[30px]'>2</span>
            </div>
        </div>
    </div>
  )
}
