

interface useLocationObject {
    pathname: string
}


const domainPath = {

    relativePath: (data: useLocationObject) => data.pathname
}

export default domainPath;