type HivQuestionProps ={
No:number,
title:String
}



export const  HivTestQuestions:HivQuestionProps[] = [
    {
        No:1,
        title: "Has your partner ever been tested for HIV?"
    },
    {
        No:2,
        title: "Has your partner ever been tested for HIV?"
    },
    {
        No:3,
        title: "Has your partner ever been tested for HIV?"
    }
    
]