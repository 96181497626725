import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../assests/styles/EmailLinkVerification.css';
import ValidationTitle from './validation';

interface EmailLinkVerificationProps {
  resendEmail: () => void;
  isSendActive: boolean;
  error: string;
}

const EmailLinkVerification: React.FC<EmailLinkVerificationProps> = ({ resendEmail, isSendActive, error }) => {
  const navigate = useNavigate();

  const handleResendClick = () => {
    resendEmail();
  };

  const handleContactUsClick = () => {
    navigate('/contact-us');
  };

  return (
    <div className="email-verification-container">
      <div className="email-verification-box">
        <h1 className="email-verification-title">Confirm Your Email</h1>
        <p className="email-verification-text">
          <b>Thank you for joining us!</b> We've sent a verification link to your email. Please click the link to verify your address and complete the registration process.
        </p>

        <div className="email-verification-options">
          {/* Section for Contact Us */}
          <div className="email-verification-section">
            <p className="email-verification-subtext">Need Help?</p>
            <button
              className="email-verification-option contact-us"
              onClick={handleContactUsClick}
            >
              Contact Us
            </button>
          </div>

          {/* Section for Resend Email */}
          <div className="email-verification-section">
            <p className="email-verification-subtext">Didn’t receive the email?</p>
            <button
              className={`email-verification-option resend-email ${isSendActive ? 'email-sent' : ''}`}
              onClick={handleResendClick}
              disabled={isSendActive}
            >
              {isSendActive ? 'Email Sent' : 'Resend Email'}
            </button>
          </div>
        </div>
        {error && <ValidationTitle title={error} />}
      </div>
    </div>
  );
};

export default EmailLinkVerification;
