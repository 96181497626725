import React, { useEffect, useState } from "react";

type ProfileField = {
  value: string | null | boolean;
  weight: number;
};

export type ProfileData = {
  filled_all_form: ProfileField;
  email_verified_at: ProfileField;
  // phone_verified_at: ProfileField;
};

type ProgressCircleProps = {
  profileData: ProfileData;
};

const calculateProfileCompletion = (profileData: ProfileData): number => {
  const totalWeight = Object.values(profileData).reduce(
    (sum, field) => sum + field.weight,
    0
  );
  const completedWeight = Object.values(profileData).reduce((sum, field) => {
    if (field.value !== null && field.value !== "" && field.value !== false) {
      return sum + field.weight;
    }
    return sum;
  }, 0);
  return (completedWeight / totalWeight) * 100;
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({ profileData }) => {
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [displayPercentage, setDisplayPercentage] = useState(0);

  useEffect(() => {
    const targetPercentage = calculateProfileCompletion(profileData);
    setCompletionPercentage(targetPercentage);
  }, [profileData]);

  useEffect(() => {
    let start = 0;
    const duration = 1500;
    const stepTime = Math.abs(Math.floor(duration / completionPercentage));

    const timer = setInterval(() => {
      start += 1;
      setDisplayPercentage(start);
      if (start >= completionPercentage) {
        clearInterval(timer);
      }
    }, stepTime);

    return () => clearInterval(timer);
  }, [completionPercentage]);

  const radius = 16; // Adjusted radius to match SVG viewBox
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset =
    circumference - (circumference * displayPercentage) / 100;

  return (
    <div className="relative flex items-center justify-center w-[60px] h-[60px] md:w-[100px] md:h-[100px] bg-white rounded-full">
      <svg
        className="w-full h-full"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="origin-center -rotate-90 transform">
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="#A165FD"
            stroke="#5303CB"
            className="stroke-current text-primary_color"
            strokeWidth="4"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            style={{ transition: 'stroke-dashoffset 0.3s ease-in-out' }}
          />
        </g>
      </svg>
      <div className="absolute flex items-center justify-center w-full h-full">
        <span className="text-sm md:text-xl font-bold text-gray-800">
          {Math.round(displayPercentage)}%
        </span>
      </div>
    </div>
  );
};

export default ProgressCircle;
