import { createAsyncThunk } from "@reduxjs/toolkit";
import RequestService from "services/requestService";
import { createRequestServiceErrorMessage } from "./requestLabServices.slice";

import { setCreateLoading } from "./requestLabServices.slice";

import {
  setFormStatus,
  setCreateError,
} from "./requestLabServices.slice";
export const getFacilities = createAsyncThunk(
  "requestLabService/getFacilities",
  async () => {
    try {
      const response = await RequestService.getMethod("facilities");
      console.log(response);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const createRequestLabService = createAsyncThunk(
  "createRequestLabService",
  async (requestLabServiceData: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await RequestService.postMethod(
        "lab/request",
        requestLabServiceData
      );
      return response.data; // Assuming your API response has a 'data' field
    } catch (error: any) {
      console.log('Error caught:', error);

      if (error.response) {
        const errorMessage = error.response.data.message || error.response.statusText;
        const statusCode = error.response.status;

        dispatch(createRequestServiceErrorMessage(error.response as string));
        dispatch(setFormStatus(false as boolean));

        switch (true) {
          case statusCode === 404:
            dispatch(setCreateError('Resource not found.'));
            break;
          case statusCode >= 500:
            dispatch(setCreateError('Server error, please try again later.' as string));
            dispatch(setCreateLoading(false as boolean))
            break;
          case errorMessage.includes('could not be found'):
            dispatch(setCreateError('Resource could not be found.'));
            break;
          case errorMessage.includes('Network Error'):
            dispatch(setCreateError('Network error, please check your connection.'));
            break;
          default:
            dispatch(setCreateError(errorMessage));
        }

        return rejectWithValue(errorMessage);
      } else if (error.request) {
     
        dispatch(setCreateError('No response from server, please try again later.'));
        return rejectWithValue('No response from server, please try again later.');
      } else {
        
        dispatch(setCreateError('An unexpected error occurred.'));
        return rejectWithValue('An unexpected error occurred.');
      }
    
    }
  }
);
