import { useState } from "react";
import ArrowIcon from "../../assests/icons/circlright.svg";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import useRequestLabService from "hooks/useRequestLabService";
const RequestLabService2: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const { dispatch, setSeletedLabTest } = useRequestLabService();
  const [selectedTest, setSelectedTest] = useState<string | null>(null);

  const handleTestClick = (test: string) => {
    dispatch(setSeletedLabTest(test));
    setSelectedTest(test)
  };
  if (selectedTest !== null) {
    onNext();
  }

  console.log(selectedTest);
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something ?
        </div>
      </div>
      <div className="heading2">
        What type of lab test service do you want?
      </div>

      <p>
        Select from the lists provided and if not available in the list, please
        select others to include yours manually
      </p>

      <div className="item-list-container">
        <ul className="item-list">
          {[
            "HIV Test",
            "Complete Blood Count (CBC)",
            "Malaria Test (RDT or microscopy)",
            "Tuberculosis (TB) Test",
            "Urinalysis",
            "Typhoid Test (Widal or PCR)",
            "Hepatitis B and C Tests",
            "Blood Glucose Test",
            "STI Tests",
            "Liver Function Tests (LFTs)",
            "Lipid Profile",
            "Basic Metabolic Panel (BMP)",
            "Thyroid Function Tests",
          ].map((test, index) => (
            <li key={index}>
              <div onClick={() => handleTestClick(test)} className="item-link">
                {test}
                <span className="icon-container">
                  <img src={ArrowIcon} alt="Arrow Icon" className="icon" />
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default RequestLabService2;
