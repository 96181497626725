import React from 'react';
import '../../assests/styles/button.css';



interface ButtonProps {

    onClick: React.MouseEventHandler<HTMLButtonElement>,
    title: string,

}

const Button: React.FC<ButtonProps> = ({ onClick, title }) => {


    return (<div className='mt-8 w-[90%] flex justify-center'>

        <button className="bg-[#8A3FFC] w-[100%] p-4 hover:bg-blue-700 text-white font-bold rounded"
            onClick={onClick}
        >
            {title}
        </button>

    </div>);


}

export default Button;