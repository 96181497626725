import React from 'react';
import '../../assests/styles/request-lab-service.css';

interface TimeInputProps {
  value: string | null;
  onChange: (value: string) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ value, onChange }) => {
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="time-input">
      <input 
        type="time" 
        value={value || ''} 
        onChange={handleTimeChange} 
        className="time-input-field"
      />
    </div>
  );
};

export default TimeInput;
