import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { StepProps } from './stepsProps';
import '../../assests/styles/request-lab-service.css';

const questions = [
  { id: 1, text: 'Have you engaged in unprotected sex with a partner whose HIV status is unknown in the last 3 months?' },
  { id: 2, text: 'Have you had unprotected sex with a partner who is HIV positive in the last 3 months?' },
  { id: 3, text: 'Have you shared injecting needles or piercing objects with anyone else in the last 3 months?' },
  { id: 4, text: 'Has it been more than 12 months since you last took an HIV test?' },
  { id: 5, text: 'Are you experiencing symptoms of a sexually transmitted infection (STI), such as blisters, sores, or unusual vaginal discharge?' },
  { id: 6, text: 'Do you have confirmed or suspected TB (tuberculosis), including a persistent cough lasting over two weeks?' },
  { id: 7, text: 'Have you recently been diagnosed with Hepatitis B or C?' },
  { id: 8, text: 'Have you experienced or perpetrated sexual violence or gender-based violence (SGBV)?' },
  { id: 9, text: 'Have you received a reactive result from an HIV self-test?' },
  { id: 10, text: 'Have you been referred by someone who tested positive for HIV (index client)?' },
  { id: 11, text: 'Have you been exposed to blood or bodily fluids from someone who is HIV positive or whose status is unknown?' },
  { id: 12, text: 'Are you exhibiting signs and symptoms of HIV infection like fever or fatigue without having an HIV test in the last month?' },
];

const Questions: React.FC<StepProps> = ({ onNext, onNotEligible }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswer = (answer: boolean) => {
    if (answer === true) {
      onNext();
    } else {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        onNotEligible && onNotEligible();
      }
    }
  };

  return (
    <div className="question-container">
      <AnimatePresence mode='wait'>
        <motion.div
          key={currentQuestionIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
        >
          <p className='question-p'>
            {questions[currentQuestionIndex].text}
          </p>
          <div className='yesNo-btn-container'>
            <button onClick={() => handleAnswer(true)}>Yes</button>
            <button onClick={() => handleAnswer(false)}>No</button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Questions;
